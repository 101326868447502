import { RefObject, useEffect, useRef, useState } from "react";
import {
  drawAreaSelection,
  SelectionWrapperClass,
  hasClickedNode,
  Coord,
  getAnchorNode,
  SelectionBounds,
  getNodes,
  getCurrentNodeInSelection,
  getEditorsMap,
  getModifierKey,
  enableScroll,
  getSelectedNodes,
} from "./selectionUtils";
import {
  SelectionKeyHandlerParams,
  handleGenericKey,
  handleSelectionLift,
  handleSelectionSink,
} from "./selectionKeyHandlers";
import { HistoryManager } from "../../hooks/useHistoryManager";
import { VirtualizedEditorState } from "../VirtualizedEditor/VirtualizedEditor";
import { EfNode } from "../../types";
import clsx from "clsx";
import { db } from "../../db";
import { copyNodesToClipboard } from "../../utils/copy";
import React from "react";

/**
 * Selection Wrapper
 * We have two types of selection - node selection and area selection
 * Node selection is handled by tiptap and area selection is handled manually by us
 *
 * This component (and useAreaSelection hook) is responsible for handling area selection
 * SelectionWrapper is a wrapper that wraps around VirtualEditor and captures mouse + keyboard events
 * to be handled manually by us
 *
 * Initially we used the default selection to handle multiple node selection, however this was overcomplicated and only really
 * use-full for partial selection of multiple nodes.
 * We also used to hold an array of selected nodes ids inside the area selection, but this was problematic because scrolling fast would
 * sometimes miss nodes that are loading in and not select them.
 *
 * Instead of holding an array of selected nodes, we hold a selection bounds object, this object contains
 * anchorNode - the node closest the start of the selection
 * currentNode - the node closest to the end of the selection
 * We are then able to calculate which nodes are selected by using the computed path of the nodes
 *
 * The flow for selection is as follows:
 * On mouse down:
 *  we update nodeRects (the rects of all nodes on screen - used to calculate node-area-cursor collisions)
 *  we calculate the anchor node and set it in the selection bounds
 * On mouse move or scroll:
 *  we look for the current node in selection (and also make sure it is in the area selection)
 *  mouse move gets it's coords from the mouse event, and scroll the the coord from the current position of the mouse + the current scroll
 * Now that we have updated bounds - nodes are able to calculate if the are selected (isNodeAreaSelected)
 * On mouse up:
 *  we clear the selection state
 * On key down:
 *  if we are in area selection, we prevent the default handling, and handle it manually
 *  currently we only handle backspace and regular keys - these are handled in selectionKeyHandlers.ts
 */
export interface AreaSelectionState {
  isSelecting: boolean; // Is the user selecting text
  isKeyboardSelection: boolean; // Is the user selecting text with keyboard
  mouseDownCoord: Coord; // Coord of mouse down
  currentMouseCoord: Coord; // Coord of mouse move
  initialClickOnNode: boolean; // Did the user click on a node
  nodeElements: Record<string, HTMLDivElement>; // Rects of all nodes on screen
  scrollRect: DOMRect | undefined;
  scrollEnabled: boolean;
}
const findNodeInState = (
  state: Pick<VirtualizedEditorState, "nodes">,
  id: string
) => state.nodes.find((n) => n.id === id);

export const useAreaSelection = (
  scrollRef: RefObject<HTMLDivElement>,
  editorState: Pick<VirtualizedEditorState, "nodes"> | undefined
) => {
  const areaSelectionRef = useRef<AreaSelectionState>({
    isSelecting: false,
    isKeyboardSelection: false,
    initialClickOnNode: false,
    mouseDownCoord: { x: 0, y: 0 },
    currentMouseCoord: { x: 0, y: 0 },
    nodeElements: {},
    scrollRect: undefined,
    scrollEnabled: false,
  });
  const areaElementRef = useRef<HTMLDivElement>(null);
  const [areaSelectedBounds, setAreaSelectedBounds] = useState<SelectionBounds>(
    {}
  );

  /**
   * Checks if the given nodes path is within the selection bounds
   * @param node
   */
  const isNodeAreaSelected = (node: EfNode) => {
    if (
      !areaSelectedBounds.anchorNode?.computed.pathInPage ||
      !areaSelectedBounds.currentNode?.computed.pathInPage ||
      !node.computed.pathInPage
    )
      return false;
    const anchor = areaSelectedBounds.anchorNode.computed.pathInPage;
    const current = areaSelectedBounds.currentNode.computed.pathInPage;
    const lower = anchor <= current ? anchor : current;
    const upper = anchor <= current ? current : anchor;
    const nodePath = node.computed.pathInPage;
    return isAreaSelectionActive() && nodePath >= lower && nodePath <= upper;
  };

  /**
   * Checks if we are currently in area selection mode
   * We could either be on regular selection mode (tiptap) or area selection so we make sure that
   * we area either selecting multiple nodes, or that we have started selecting outside of node (where
   * area selection rect is shown)
   */
  const isAreaSelectionActive = () => {
    const isAnyNodeSelected = !!areaSelectedBounds.currentNode;
    const isSelecting = areaSelectionRef.current.isSelecting;
    const isInitialClickNotOnNode =
      !areaSelectionRef.current.initialClickOnNode;
    const isMultipleNodesSelected =
      isAnyNodeSelected &&
      areaSelectedBounds.anchorNode?.id !== areaSelectedBounds.currentNode?.id;

    return (
      (isAnyNodeSelected || isSelecting) &&
      (isInitialClickNotOnNode || isMultipleNodesSelected)
    );
  };

  const onContainerScroll = () => {
    if (!areaSelectionRef.current.isSelecting) return;
    // When the container scrolls we need to update the node rects
    areaSelectionRef.current.nodeElements = getNodes();
    const {
      scrollRect,
      currentMouseCoord: { x, y },
    } = areaSelectionRef.current;
    const moveCoord = {
      // Calculate the mouse coord relative to the scroll container + current scroll
      x: x - (scrollRect?.x || 0),
      y: y - (scrollRect?.y || 0) + (scrollRef.current?.scrollTop || 0),
    };
    updateSelection(moveCoord);
  };

  const updateSelection = (moveCoord: Coord) => {
    // Draw selection
    if (areaElementRef.current) {
      drawAreaSelection(
        areaElementRef.current,
        areaSelectionRef.current.mouseDownCoord,
        moveCoord
      );
    }

    // Calculate closest node to mouse move coord for new current node
    const currentNodeID = getCurrentNodeInSelection(
      areaSelectionRef.current.mouseDownCoord,
      moveCoord,
      areaSelectionRef.current.nodeElements
    );
    if (!editorState) return;
    const currentNode = currentNodeID
      ? findNodeInState(editorState, currentNodeID)
      : undefined;

    // If the currentNode has changes update state
    if (areaSelectedBounds.currentNode?.id !== currentNode?.id)
      setAreaSelectedBounds(({ anchorNode }) => {
        return {
          anchorNode,
          currentNode,
        };
      });
  };

  const isNodeSelected = (node: EfNode) => isNodeAreaSelected(node);

  const selectNode = (anchorOrCurrent: EfNode, current?: EfNode) => {
    areaSelectionRef.current.isKeyboardSelection = true;
    if (!areaSelectedBounds.anchorNode) {
      setAreaSelectedBounds({
        anchorNode: anchorOrCurrent,
        currentNode: current,
      });
    } else {
      setAreaSelectedBounds({
        anchorNode: areaSelectedBounds.anchorNode,
        currentNode: anchorOrCurrent,
      });
    }
  };

  const clearSelectedNodes = () => {
    areaSelectionRef.current.isKeyboardSelection = false;
    setAreaSelectedBounds({});
  };

  return {
    areaSelectionRef,
    areaElementRef,
    areaSelectedBounds,
    setAreaSelectedBounds,
    isNodeAreaSelected,
    isAreaSelectionActive,
    onContainerScroll,
    updateSelection,
    isNodeSelected,
    selectNode,
    clearSelectedNodes,
  };
};

interface SelectionWrapperProps {
  children: React.ReactNode;
  history: HistoryManager;
  virtualEditorState: Pick<VirtualizedEditorState, "nodes">;
  areaSelection: ReturnType<typeof useAreaSelection>;
  scrollRef: RefObject<HTMLDivElement>;
  disabled?: boolean;
  hideArea?: boolean;
  rootNodeId: string;
}
export function SelectionWrapper({
  children,
  history,
  virtualEditorState,
  areaSelection: {
    areaElementRef,
    areaSelectionRef,
    areaSelectedBounds,
    setAreaSelectedBounds,
    isAreaSelectionActive,
    updateSelection,
  },
  scrollRef,
  hideArea,
  rootNodeId,
}: SelectionWrapperProps) {
  const findNodes = async () => {
    let firstNode: EfNode | undefined = undefined;
    const middleNodes: EfNode[] = [];
    let lastNode: EfNode | undefined;
    if (
      !areaSelectedBounds.anchorNode?.computed.pathInPage ||
      !areaSelectedBounds.currentNode?.computed.pathInPage
    ) {
      if (!firstNode) throw new Error("First node must be defined");
      return [firstNode, middleNodes, lastNode] as const;
    }

    const nodes = await getSelectedNodes(areaSelectedBounds);

    for (let i = 0; i < nodes.length; i++) {
      const efNode = nodes[i];

      if (i === 0) {
        firstNode = efNode;
      } else if (i === nodes.length - 1) {
        lastNode = efNode;
      } else if (efNode) {
        middleNodes.push(efNode);
      }
    }

    if (!firstNode) throw new Error("First node must be defined");
    return [firstNode, middleNodes, lastNode] as const;
  };

  const handleKeyOnSelection = async (params: SelectionKeyHandlerParams) => {
    const { keyDownEvent, nodesPromise } = params;
    const key = keyDownEvent.key;
    const modifier = getModifierKey(keyDownEvent);
    if (key.length <= 1 && !modifier) {
      // This is the case for a regular key
      await handleGenericKey({ ...params, key });
    } else {
      const pressedKey = modifier ? `${modifier}+${key.toUpperCase()}` : key;
      switch (pressedKey) {
        case "Backspace":
          await handleGenericKey({ ...params, key: "" });
          break;
        case "Tab":
          await handleSelectionSink({ ...params, key });
          break;
        case "Shift+Tab":
          await handleSelectionLift({ ...params, key });
          break;
        case "Meta+C":
        case "Control+C":
          await copyNodesToClipboard(nodesPromise);
          break;
        case "Meta+X":
        case "Control+X":
          await copyNodesToClipboard(nodesPromise);
          await handleGenericKey({ ...params, key: "" });
          break;
        default:
          // If key wasn't handled return false
          return false;
      }
    }

    return true;
  };

  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.button !== 0) return;
    // Update refs
    const scrollRect = scrollRef.current?.getBoundingClientRect();
    areaSelectionRef.current.scrollRect = scrollRect;
    areaSelectionRef.current.nodeElements = getNodes({ parent: scrollRef });
    // Calculate
    areaSelectionRef.current.mouseDownCoord = {
      x: event.clientX - (scrollRect?.x || 0),
      y:
        event.clientY -
        (scrollRect?.y || 0) +
        (scrollRef.current?.scrollTop || 0),
    };
    areaSelectionRef.current.isSelecting = true;
    areaSelectionRef.current.isKeyboardSelection = false;
    areaSelectionRef.current.initialClickOnNode = hasClickedNode(
      event.target as HTMLElement,
      Object.values(areaSelectionRef.current.nodeElements)
    );

    // Get anchor node - the anchor node is the closest node to the mouse down coord
    const anchorNodeID = getAnchorNode(
      areaSelectionRef.current.mouseDownCoord,
      areaSelectionRef.current.nodeElements
    );
    const anchorNode =
      (anchorNodeID && findNodeInState(virtualEditorState, anchorNodeID)) ||
      undefined;

    // Update state
    setAreaSelectedBounds({
      anchorNode: anchorNode,
      currentNode: undefined,
    });
  };

  const onMouseUp = () => {
    if (isAreaSelectionActive() && areaSelectedBounds.anchorNode?.id) {
      // If we are in area selection un-focus focused editor to hide the cursor
      const focusedEditor = getEditorsMap()[areaSelectedBounds.anchorNode.id];
      if (focusedEditor) {
        focusedEditor.commands.blur();
      }
    }
    areaSelectionRef.current.isSelecting = false;
    areaSelectionRef.current.mouseDownCoord = { x: 0, y: 0 };
    areaSelectionRef.current.nodeElements = {};
    areaSelectionRef.current.scrollEnabled = false;
    areaElementRef.current?.style.setProperty("display", "none");
  };

  const onMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!areaSelectionRef.current.isSelecting) return;
    if (!areaSelectionRef.current.initialClickOnNode) {
      // Only show area selection if the user didn't click on a node
      areaElementRef.current?.style.setProperty("display", "block");
    }
    areaSelectionRef.current.currentMouseCoord = { x: e.clientX, y: e.clientY };
    const { scrollRect } = areaSelectionRef.current;
    const moveCoord = {
      // Calculate the mouse coord relative to the scroll container + current scroll
      x: e.clientX - (scrollRect?.x || 0),
      y: e.clientY - (scrollRect?.y || 0) + (scrollRef.current?.scrollTop || 0),
    };
    updateSelection(moveCoord);

    if (areaSelectionRef.current.scrollEnabled || !scrollRect) return;

    // If the mouse is close to the top or bottom of the scroll container, enable scroll
    const shouldScroll =
      e.clientY > scrollRect.bottom - 30 || e.clientY < scrollRect.top + 30;
    if (shouldScroll) {
      enableScroll(rootNodeId, areaSelectedBounds.anchorNode?.id || "");
      areaSelectionRef.current.scrollEnabled = true;
    }
  };

  const onKeyDown = async (e: KeyboardEvent) => {
    if (!areaSelectedBounds.anchorNode) return;

    const isKeyboardSelection = areaSelectionRef.current.isKeyboardSelection;

    if (
      isKeyboardSelection &&
      (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Shift")
    )
      return;

    const oneNodeInSelection =
      areaSelectedBounds.currentNode &&
      areaSelectedBounds.currentNode?.id === areaSelectedBounds.anchorNode?.id;

    if (
      // If we have on node in selection and we initially clicked node - handle in tiptap
      ((oneNodeInSelection && areaSelectionRef.current.initialClickOnNode) ||
        // If we have no currentNode (so no area selection) reset state
        !areaSelectedBounds.currentNode) &&
      // We are not selecting with keyboard
      !isKeyboardSelection
    ) {
      return setAreaSelectedBounds({});
    }

    // If more than one node is selected, do not propagate to tiptap
    e.preventDefault();
    e.stopPropagation();

    const nodesPromise = findNodes();

    const handled = await handleKeyOnSelection({
      keyDownEvent: e,
      nodesPromise,
      history,
    });

    if (handled) setAreaSelectedBounds({});
    areaSelectionRef.current.isKeyboardSelection = false;
  };

  useEffect(() => {
    // Using document for keyboard events because sometimes it does not register on selection wrapper
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [areaSelectedBounds]);

  return (
    <div
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      className={clsx(
        SelectionWrapperClass,
        isAreaSelectionActive() ? "hide-selection" : ""
      )}
      ref={scrollRef}
    >
      {children}
      <div
        ref={areaElementRef}
        className={clsx("absolute border-2", hideArea && "hidden")}
      />
    </div>
  );
}
