import { v4 } from "uuid";
import { EfNodeType } from "../graphql";
import { createNode } from "./sync";
import { db } from "../db";
import { maxBy } from "lodash";
import { EfNode, EfNodeData } from "../types";

export async function createTagNode(
  id: string = v4(),
  titleText: string,
  parentId: string | null = null
) {
  const existingNodes = await db.nodes
    .where("nodeType")
    .equals(EfNodeType.Tag)
    .toArray();
  //this check makes sure that a tag with the same name will not be created again
  if (
    !!existingNodes.find(
      ({ titleText: title, deleted, parentId: currParentId }) =>
        title?.toLowerCase() === titleText.toLowerCase() &&
        !deleted &&
        currParentId === (parentId ?? null)
    )
  ) {
    return console.warn(
      "we are not creating a new tag becuase it already exists"
    );
  }
  await createNode({
    id,
    nodeType: EfNodeType.Tag,
    contentText: "",
    titleText,
    parentId,
    position: null,
    tagIds: [],
    referencedPageIds: [],
    properties: {
      collapsed: false,
    },
  });
}

export const sortNodesBasedOnRecency = async (
  nodesToSort: (Partial<EfNode> & {
    label?: string;
    actualQuery?: string;
    tagNodesLatestModifiedTime?: number;
  })[],
  key: "tagIds" | "referencedPageIds" | "mentionIds"
) => {
  const allNodes = await Promise.allSettled(
    nodesToSort.map(async (node) => {
      const relatedNodes = await db.nodes.where(key).equals(node.id!).toArray();
      const latestModifiedTimeNode = maxBy(
        relatedNodes,
        (relatedNode) =>
          relatedNode.clientModifiedTime?.getTime?.() ||
          relatedNode.modifiedTime?.getTime?.() ||
          0
      );
      return {
        ...node,
        tagNodesLatestModifiedTime:
          node.tagNodesLatestModifiedTime ||
          latestModifiedTimeNode?.clientModifiedTime?.getTime?.() ||
          latestModifiedTimeNode?.modifiedTime?.getTime?.() ||
          0,
      };
    })
  );

  return allNodes
    .map((node) => {
      if (node.status === "fulfilled") return node.value;
      return undefined;
    })
    .filter((node) => !!node)
    .sort((a, b) => {
      if (b?.tagNodesLatestModifiedTime === a?.tagNodesLatestModifiedTime) {
        const textA = a?.label || a?.titleText || "";
        const textB = b?.label || b?.titleText || "";
        return textA?.localeCompare(textB);
      }
      return (
        (b?.tagNodesLatestModifiedTime || 0) -
        (a?.tagNodesLatestModifiedTime || 0)
      );
    }) as EfNodeData[];
};
