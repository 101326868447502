import { findNodeAncestors } from "./findNodeAncestors";
import memoize from "fast-memoize";
import { findNodeDescendents } from "./findNodeDescendents";
import MapExpire from "map-expire/MapExpire";

const serializer = function (args: any[]) {
  return JSON.stringify(arguments);
};

// custom cache to store data
// this is created so that specific key can be cleared when required.
class Cache {
  private options = {
    capacity: 2000, // max of 2K items
    duration: 3600000, // 1hr max time for each key
  };
  private cache;
  constructor(options?: { capacity: number; duration: number }) {
    if (options) {
      this.options = options;
    }
    this.cache = new MapExpire([], this.options);
  }

  has(key: any) {
    return !!this.cache.get(key);
  }
  get(key: any) {
    return this.cache.get(key);
  }
  set(key: any, value: any) {
    this.cache.set(key, value);
  }
  // this method will not be clled by fast memoize
  // its purpose is to clear a key from our code.
  // two places deleteKey is used wrt tags
  // a. titleText property is updated(i.e tagName)
  // b. Parent of tag is updated
  deleteKey(key: any) {
    this.cache.delete(serializer(key));
  }
  clear() {
    this.cache = new MapExpire([], this.options);
  }
}

export const tagFullNameCache = new Cache();

export const clearAllChildTags = async (tagId: string) => {
  const descendentIds = await findNodeDescendents(tagId);
  descendentIds.forEach((descendentId) => {
    tagFullNameCache.deleteKey(descendentId);
  });
};

export const getTagWithFullName = async (tagId: string) => {
  const ancestors = await findNodeAncestors(tagId);
  const fullName = ancestors
    .map((a) => a.titleText)
    .reverse()
    .join(".");
  return { ...ancestors[0], fullName };
};

export const getTagWithFullNameCached = memoize(getTagWithFullName, {
  cache: {
    create: function create() {
      return tagFullNameCache;
    },
  },
  serializer: serializer,
});
