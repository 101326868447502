import { uniq } from "lodash";
import { EfNode, EfNodeEditorData } from "../types";
import { getTagWithFullNameCached } from "./getTagWithFullName";
import { db } from "../db";
import { isSafari } from "react-device-detect";

const NodeTag: keyof HTMLElementTagNameMap = "li";
const ParentTag: keyof HTMLElementTagNameMap = "ul";

export const COPIED_NODE_DATA_ATTRIBUTE = "data-efnode";

export type ClipboardEfNode = EfNodeEditorData & {
  children?: ClipboardEfNode[]; // Children of the node
  isRoot?: boolean; // Is the node a root node of the copied selection (has no parent in selection)
};

/**
 * On firefox we cannot use the clipboard API to copy HTML content https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem#browser_compatibility
 * It is only available with a special permission that has to be set manually,
 * to work around this we create a temporary div, insert the HTML content into it, select it and execute the copy command
 *
 * In addition, when using the clipboard.copy api, any html that is copied is wrapped in <html> and <body> tags
 * then when pasted in notion, it is pasted as a single block instead of many blocks. So we use this instead of clipboard.copy
 * to overcome this.
 */
const copyHTML = (htmlContent: string) => {
  // Create a temporary editable div to hold the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.contentEditable = "true";
  document.body.appendChild(tempDiv);
  tempDiv.innerHTML = htmlContent; // Insert the HTML content into the div

  // Elements are copied with styles, which interferes with the pasting in some editors line Apple Notes
  const removeAllStyles = (element: Element) => {
    if (element instanceof HTMLElement) {
      element.style.all = "revert";
    }
    Array.from(element.children).forEach(removeAllStyles);
  };
  removeAllStyles(tempDiv);

  // Select the content
  const range = document.createRange();
  range.selectNodeContents(tempDiv);
  const selection = window.getSelection();

  if (!selection) return;
  selection.removeAllRanges(); // Clear any existing selections
  selection.addRange(range); // Select the HTML content

  // Execute the copy command
  try {
    // While execCommand is technically deprecated, it should still be ok to use
    // with the "copy" command. https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
    document.execCommand("copy");
  } catch (err) {
    console.error("Failed to copy HTML content to clipboard", err);
  }

  // Clean up: remove the temporary div from the document
  document.body.removeChild(tempDiv);
};

/**
 * Copies EfNodes to clipboard
 * Creates two variations, the html variation for editors who support it (eg: notion)
 * and the plain text variation for editors who don't
 * safari mandates that any clipboard.write call must be called within a use fired event,
 * as a result this function MUST BE SYNCRONOUS. Which is why we pass a promise that returns the nodes
 * and not the nodes themselves
 * @param nodesPromise - a promise that returns the nodes to be copied
 */
export const copyNodesToClipboard = (
  nodesPromise: Promise<readonly [EfNode, EfNode[], EfNode | undefined]>
) => {
  const getSelection = async () => {
    // Get all nodes in selection from nodesPromise
    const [firstNode, middleNodes, lastNode] = await nodesPromise;
    const allNodes = [firstNode, ...middleNodes];
    if (lastNode) allNodes.push(lastNode);
    return createHTMLSelection(allNodes);
  };
  const selectionPromise = getSelection();

  const createCopiedText = async () => {
    const selection = await selectionPromise;
    return new Blob([selection.textContent || ""], {
      type: "text/plain",
    });
  };
  const createCopiedHTML = async () => {
    const selection = await selectionPromise;
    return new Blob([selection.outerHTML], {
      type: "text/html",
    });
  };

  const copyNodes = async () => {
    const nodes = await selectionPromise;
    copyHTML(nodes.outerHTML);
  };

  // On all browsers other than safari we copy with execCommand
  if (!isSafari) return copyNodes();

  // On safari we use the clipboard api, since we cannot use execCommand syncronously
  const clipboardItem = new ClipboardItem({
    "text/plain": createCopiedText(),
    "text/html": createCopiedHTML(),
  });

  return navigator.clipboard.write([clipboardItem]);
};

/**
 * Creates a tree of EfNodes from a flat list of EfNodes
 * Nodes that have no parent (parent was not in selection) will be assigned rootNode = true
 * Nodes that have children will have them assigned to the children property
 * This makes it easier to construct the html tree later
 * @param nodes
 */
const buildNodeTree = (nodes: EfNode[]) => {
  // Map of nodeID to node
  const nodeMap = new Map<string, ClipboardEfNode>();
  // Roots of the tree (we could have multiple)
  const roots: ClipboardEfNode[] = [];

  // Initialize each node in the map
  nodes.forEach((node: ClipboardEfNode) => {
    node.children = [];
    nodeMap.set(node.id, node);
  });

  // Now we will go over all of the nodes and assign their children
  // and if they are root nodes or not
  nodes.forEach((node) => {
    if (node.parentId && nodeMap.has(node.parentId)) {
      // If the node has a parent and the parent exists in the selection,
      // push the node to the parent children
      const parent = nodeMap.get(node.parentId);
      parent?.children?.push(node);
    } else {
      // If the node does not have a parent or the parent does not exist in the selection
      // The node is a root node
      roots.push({ ...node, isRoot: true });
    }
  });
  return roots;
};

/**
 * Create html node of the EfNode
 * The nodes data will be stored in the data-efnode attribute and later parsed in paste
 * Other editors will receive the nodes in ui/li formatted list
 * @param node
 */
const efNodeToDOM = (parent: Element, node: ClipboardEfNode) => {
  // Create node item
  const domNode = document.createElement(NodeTag);
  // Create attribute for parsing later
  const nodeAttr = document.createAttribute(COPIED_NODE_DATA_ATTRIBUTE);
  const { children, ...nodeData } = { ...node };
  nodeAttr.value = JSON.stringify(nodeData);
  // Assign data attribute to node
  domNode.attributes.setNamedItem(nodeAttr);

  domNode.innerHTML = node.contentText || "";

  let innerList: HTMLUListElement | null = null;
  if (children?.length) {
    // If the node has children, create and assign them to the node
    innerList = document.createElement(ParentTag);
    children.forEach((child) => {
      const [node, children] = efNodeToDOM(domNode, child);
      innerList?.appendChild(node);
      if (children) {
        innerList?.appendChild(children);
      }
    });
  }
  return [domNode, innerList] as const;
};

/**
 * Creates html nodes from EfNodes
 * @param nodes
 */
const createHTMLSelection = async (nodes: EfNode[]) => {
  // Create initial list element
  const selectionRoot = document.createElement(ParentTag);
  // Create node tree
  const roots = buildNodeTree(nodes);
  // For each root build the html node
  roots.forEach((root) => {
    const [domNode, children] = efNodeToDOM(selectionRoot, root);
    selectionRoot.appendChild(domNode);
    if (children) {
      selectionRoot.appendChild(children);
    }
  });
  // Inject tags
  const tagIDs = uniq(nodes.flatMap(({ tagIds }) => tagIds || []));
  const tags = await Promise.all(tagIDs.map(getTagWithFullNameCached));
  tags.forEach((tag) =>
    injectNodes(selectionRoot, "tag", tag.id, `<span>#${tag.fullName}</span>`)
  );
  // Inject mentions
  const mentionIDs = uniq(nodes.flatMap(({ mentionIds }) => mentionIds || []));
  const mentions = await db.nodes.where("id").anyOf(mentionIDs).toArray();
  mentions.forEach((mention) =>
    injectNodes(
      selectionRoot,
      "mention",
      mention.id,
      `<span>@${mention.titleText}</span>`
    )
  );

  return selectionRoot;
};

const injectNodes = (
  document: Element,
  dataType: string,
  id: string,
  replaceWith: string
) => {
  const nodes = document.querySelectorAll(
    `[data-type='${dataType}'][data-id='${id}']`
  );
  nodes.forEach((node) => {
    node.outerHTML = replaceWith;
  });
};

/**
 * This functions receives raw html, and extracts the ClipboardEfNodes from it
 * by querying the data-efnode attribute and parsing its contents
 */
export const extractEfNodesFromHTML = (html: string) => {
  const efNodes: ClipboardEfNode[] = [];
  const htmlNodes = new DOMParser().parseFromString(html, "text/html");
  const nodes = htmlNodes.querySelectorAll(`[${COPIED_NODE_DATA_ATTRIBUTE}]`);
  nodes.forEach((node) => {
    const nodeData = node.getAttribute(COPIED_NODE_DATA_ATTRIBUTE);
    if (nodeData) {
      efNodes.push(JSON.parse(nodeData));
    }
  });
  return efNodes;
};
