import { EfNodeType, Maybe, PushMutation } from "../graphql";

export type EfNodeEditorData = {
  id: string;
  nodeType: EfNodeType;
  parentId: Maybe<string>;
  position: Maybe<string>;
  titleText: Maybe<string>;
  contentText: Maybe<string>;
  tagIds: Maybe<Array<string>>;
  referencedPageIds: Maybe<Array<string>>;
  mentionIds?: Maybe<Array<string>>;
  fileIds?: Maybe<Array<string>>;
  rootId?: Maybe<string>;
  properties: Maybe<{
    collapsed?: boolean;
    taskStatus?: "PENDING" | "COMPLETED";
    taggedNodesListMode?: "HIERARCHICAL" | "EXACT";
    fileContentType?: Maybe<string>;
    fileName?: Maybe<string>;
    fileUrl?: Maybe<string>;
    fileDimensions?: Maybe<{ width: number; height: number }>;
    isSystemCreated?: Maybe<boolean>;
    contactEmails?: Maybe<Array<{ email: string }>>;
  }>;
};

export type FileNodeData = {
  id: string;
  contentText: string;
  properties: Maybe<{
    fileUrl?: Maybe<File>;
    fileContentType?: Maybe<string>;
    fileName?: Maybe<string>;
    fileCreatedTime?: Maybe<Date>;
  }>;
};

export type FileQueueItem = FileNodeData & { uploaded: number };

export type EfNodeData = EfNodeEditorData & {
  deleted: boolean;
  workspaceId: Maybe<string>;
  version: Maybe<number>;
  modifiedTime?: Maybe<Date>;
  createdTime?: Maybe<Date>;
  clientModifiedTime?: Maybe<Date>;
};

export type EfNode = EfNodeData & {
  clientTimestamp: Maybe<number>;
  oldNodeData: Maybe<Partial<EfNodeData>>;
  computed: {
    path?: string;
    visible?: 1 | null;
    pathInPage?: string;
  };
  relatedNodesModifiedTimeStamp: number; // Only for tag and contacts it is populated for now but can be used for other nodeTypes in future
};

export type PmNode = {
  type?: string;
  attrs?: Record<string, any>;
  content?: PmNode[];
  marks?: {
    type: string;
    attrs?: Record<string, any>;
    [key: string]: any;
  }[];
  text?: string;
};

export type Settings = GeneralSettings | UserPreferenceSettings;

export type GeneralSettings = {
  id: "general";
  lastClientTimestamp?: number;
  lastServerCursor?: string;
  schemaVersion?: number;
};

export type UserPreferenceSettings = {
  id: "userPreference";
  tag?: SidePanelSection;
  contact?: SidePanelSection;
  page?: SidePanelSection;
  landingPage?: string;
  lastVisitedNode?: Record<string, string[]>;
  pageProperties?: Record<string, PageProperties>;
};

export enum FocusModeType {
  SHOW_OTHER_BLOCKS = "SHOW_OTHER_BLOCKS",
  DIM_OTHER_BLOCKS = "DIM_OTHER_BLOCKS",
  HIDE_OTHER_BLOCKS = "HIDE_OTHER_BLOCKS",
}

export type PageProperties = {
  focusMode: {
    type: FocusModeType;
  };
  listOrderType?: ListOrder;
};

export type SidePanelSection = {
  isCollapsed?: string;
  sortOrder?: "increasing" | "decreasing";
  sortBy?: "name" | "createdDate" | "manual" | "recency";
  manualSortOrder?: string[];
};

export type ApplyPatch = PushMutation["applyPatches"][number];

export type Selection = {
  nodeId: string;
  from: number;
  to: number;
};

export enum TabsMessageType {
  HIDE_LOADER = "hideLoader",
  VERSION_MISSMATCH = "versionMissmatch",
  RELOAD = "reload",
  NEW_CLIENT_PING = "newClientPing",
  TOTAL_NODES = "totalNodes",
  NODES_LEFT_TO_PULL = "nodesLeftToPull",
}

export type EfContentWithIds = Pick<
  EfNode,
  "tagIds" | "referencedPageIds" | "mentionIds" | "fileIds" | "contentText"
>;

export enum ListOrder {
  Chronological = "Order Chronologically",
  ByPage = "Group by Page",
  ByPriority = "Group by Priority",
}
