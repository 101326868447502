import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { useDrag } from "react-dnd";
import {
  MdAdd,
  MdChevronRight,
  MdClose,
  MdDragIndicator,
  MdEdit,
  MdExpandMore,
} from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { DataTest } from "../../../tests/e2e/utils/constants";
import { db } from "../../db";
import { EfNodeType } from "../../graphql";
import { ImMoveUp } from "react-icons/im";
import { btn, sidebarBtn, sidebarBtnActive } from "../../styles/classes";
import { EfNode } from "../../types";
import { deleteNodeAndChildren, updateNode } from "../../utils";
import { createTagNode } from "../../utils/tags";
import { useTreeDrop } from "../../hooks/useTreeDrop";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { HiHashtag } from "react-icons/hi";
import { useSectionDetails } from "../../hooks/useSectionDetails";
import { cloneDeep, isNil } from "lodash";
import { SidePanelAction } from "./SidePanelAction";
import { getEmptyImage } from "react-dnd-html5-backend";
import { clearAllChildTags } from "../../utils/getTagWithFullName";
import { getParentNodes } from "../../utils/nodes";
import { sortSidePanelTree } from "./utils/sidePanelActionUtils";

export const TagTree = memo(() => {
  const {
    isCollapsed,
    sortBy,
    sortOrder,
    manualSortOrder,
    setCollapsed,
    setSortByOrSortOrder,
    setManualSortOrder,
  } = useSectionDetails("tag");

  let tags = useLiveQuery(
    async () =>
      await db.nodes
        .where("nodeType")
        .equals(EfNodeType.Tag)
        .filter((tag) => !tag.deleted)
        .toArray()
  );
  const sortedTags = useMemo(
    () => sortSidePanelTree(tags, sortBy, manualSortOrder, sortOrder) || [],
    [tags, sortBy, manualSortOrder, sortOrder]
  );

  const updateManualOrder = useCallback(
    (sortedPages: EfNode[]) => {
      setManualSortOrder(sortedPages.map(({ id }) => id));
    },
    [setManualSortOrder]
  );

  const onChangeSortOrder = (key: "sortBy" | "sortOrder", value: string) => {
    if (key === "sortBy" && value === "manual" && !manualSortOrder?.length) {
      updateManualOrder(sortedTags || []);
    }
    setSortByOrSortOrder(key, value);
  };

  return (
    <div>
      <div className="flex items-center justify-between pr-1 pl-3">
        <button
          onClick={setCollapsed}
          className={clsx("grow", {
            ["pointer-events-none"]: !sortedTags?.length,
          })}
        >
          <div className="flex items-center">
            {isCollapsed ? (
              <MdChevronRight
                className={clsx("w-5 h-5", {
                  ["invisible"]: !sortedTags?.length,
                })}
              />
            ) : (
              <MdExpandMore
                className={clsx("w-5 h-5", {
                  ["invisible"]: !sortedTags?.length,
                })}
              />
            )}
            <HiHashtag />
            <div className="ml-4">Tags</div>
          </div>
        </button>
        <div className="flex">
          <SidePanelAction
            sortBy={sortBy}
            sortOrder={sortOrder}
            onChange={onChangeSortOrder}
          />
          <button
            className={clsx(btn, "!p-1")}
            onClick={() => {
              const titleText = prompt("Tag name");
              if (!titleText) return;
              createTagNode(v4(), titleText, null);
              isCollapsed && setCollapsed();
            }}
          >
            <MdAdd className="w-5 h-5" />
          </button>
        </div>
      </div>
      {!isCollapsed && (
        <TagNode
          tag={null}
          tags={sortedTags || []}
          canDrop={sortBy === "manual"}
          showDragHandles={sortBy === "manual"}
          updateManualOrder={updateManualOrder}
        />
      )}
    </div>
  );
});

export const TagNode = memo(
  ({
    tag,
    tags,
    canDrop,
    isLast,
    index,
    showDragHandles,
    level = 0,
    updateManualOrder,
    markParentAsOver,
  }: {
    tag: EfNode | null;
    tags: EfNode[];
    canDrop: boolean;
    index?: number;
    isLast?: boolean;
    showDragHandles?: boolean;
    level?: number;
    updateManualOrder?: (nodes: EfNode[]) => void;
    markParentAsOver?: (isOverFromChild: boolean) => void;
  }) => {
    const parentId = tag ? tag.id : null;
    const childTags = tags.filter((tag) => tag.parentId === parentId);
    const hasChildren = childTags.length > 0;
    const isCollapsed = tag?.properties?.collapsed === true;
    const [isOverLastFromChild, setIsOverLastFromChild] = useState(false);

    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        item: {
          index,
          itemValue: tag,
          level,
          hasChildren,
        },
        type: EfNodeType.Tag,
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [tag]
    );

    // This adds empty image to the Drag Preview
    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    const changePosition = (currentIndex: number, hoverIndex?: number) => {
      if (isNil(hoverIndex)) {
        return;
      }
      const clonedCards = [...cloneDeep(tags)];
      const removedItem = clonedCards.splice(currentIndex, 1)[0];
      clonedCards.splice(hoverIndex, 0, removedItem);
      updateManualOrder?.(clonedCards);
    };

    const onClickMoveToTop = useCallback(() => {
      if (!tag) {
        return;
      }
      const clonedCards = cloneDeep(tags);
      updateManualOrder?.([
        tag,
        ...clonedCards.filter(({ id }) => tag.id !== id),
      ]);
    }, [tags, updateManualOrder, tag]);

    const {
      drop,
      dropBetween,
      dropBetweenLast,
      isOverCurrent,
      isOverCurrentBetween,
      isOverCurrentBetweenLast,
    } = useTreeDrop({
      accept: EfNodeType.Tag,
      canDrop,
      isDragging,
      node: tag,
      index,
      changePosition,
    });

    const showDropOutline =
      canDrop && !isDragging && (isOverCurrent || isOverLastFromChild);

    useEffect(() => {
      markParentAsOver?.(isOverCurrentBetweenLast || isOverCurrentBetween);
    }, [isOverCurrentBetweenLast, isOverCurrentBetween]);

    return (
      <>
        {tag && (
          <div
            ref={dropBetween}
            className={`relative h-[6px] w-full ${
              canDrop && isOverCurrentBetween && "bg-blue-400"
            }`}
          />
        )}
        <div ref={drop} data-testid={DataTest.TagPage}>
          <div
            className={clsx(
              showDropOutline && "!bg-blue-200",
              isDragging && "opacity-40"
            )}
          >
            {tag && (
              <NavLink key={tag.id} to={`/tags/${tag.id}`}>
                {({ isActive }) => (
                  <div ref={drag}>
                    <TagElement
                      tag={tag}
                      isActive={isActive}
                      showDragHandles={showDragHandles}
                      hasChildren={hasChildren}
                      level={level}
                      onClickMoveToTop={onClickMoveToTop}
                    />
                  </div>
                )}
              </NavLink>
            )}
            {hasChildren && !isCollapsed && (
              <div
                className={clsx(parentId && showDropOutline && "!bg-blue-200")}
              >
                {childTags.map((tag, idx) => {
                  return (
                    <TagNode
                      key={tag.id}
                      tag={tag}
                      tags={tags}
                      canDrop={canDrop && !isDragging}
                      level={level + 1}
                      showDragHandles={showDragHandles}
                      index={tags.findIndex(({ id }) => id === tag.id)}
                      isLast={childTags.length - 1 === idx}
                      updateManualOrder={updateManualOrder}
                      markParentAsOver={(value) => {
                        setIsOverLastFromChild(value);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {isLast && (
          <div
            ref={dropBetweenLast}
            className={`relative h-[6px] ${
              canDrop && isOverCurrentBetweenLast && "bg-blue-400"
            }`}
          />
        )}
      </>
    );
  }
);

export const TagElement = ({
  tag,
  isActive,
  showDragHandles,
  hasChildren,
  level,
  onClickMoveToTop,
}: {
  tag: EfNode | null;
  isActive: boolean;
  showDragHandles?: boolean;
  hasChildren: boolean;
  hideActions?: boolean;
  level: number;
  onClickMoveToTop?: () => void;
}) => {
  return (
    <div
      className={clsx(
        "px-1 !ml-1 mr-1",
        sidebarBtn,
        isActive && sidebarBtnActive,
        "group truncate items-stretch"
      )}
    >
      <div className="flex items-center">
        <ImMoveUp
          title="move to top"
          onClick={onClickMoveToTop}
          className={clsx(
            "my-1 ml-2 text-lg opacity-0 group-hover:opacity-100",
            {
              ["!opacity-0"]: !showDragHandles,
            }
          )}
        />
        <MdDragIndicator
          className={clsx("my-1 ml-2", { ["opacity-0"]: !showDragHandles })}
        />
      </div>
      <div
        style={{
          marginLeft: `${16 * ((level || 1) - 1)}px`,
        }}
        className="flex items-center justify-between min-w-0 w-full"
      >
        <TagNodeCollapse tag={tag!} hide={!hasChildren} />
        <div className="flex-1 truncate" data-testid={DataTest.TagTitle}>
          {tag?.titleText}
        </div>
        <TagNodeActions tag={tag!} />
      </div>
    </div>
  );
};

export function TagNodeCollapse({ hide, tag }: { tag: EfNode; hide: boolean }) {
  return (
    <button
      className={clsx("cursor-pointer", { ["opacity-0"]: hide })}
      onClick={(e) => {
        e.preventDefault();
        updateNode({
          ...tag,
          properties: {
            ...tag.properties,
            collapsed: !tag?.properties?.collapsed,
          },
        });
      }}
    >
      {tag?.properties?.collapsed ? (
        <MdChevronRight className="w-5 h-5" />
      ) : (
        <MdExpandMore className="w-5 h-5" />
      )}
    </button>
  );
}

export function TagNodeActions({ tag }: { tag: EfNode }) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="space-x-2 sm:space-x-1 flex items-center justify-center">
      <button
        className="cursor-pointer lg:hidden group-hover:block"
        onClick={(evt) => {
          evt.preventDefault();
          const titleText = prompt("Tag name");
          if (!titleText) return;
          createTagNode(v4(), titleText, tag.id);
        }}
      >
        <MdAdd className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
      <button
        className="cursor-pointer lg:opacity-0 group-hover:opacity-100 transition-all"
        data-testid={DataTest.EditTag}
        onClick={(evt) => {
          evt.preventDefault();
          const titleText = prompt("Tag name", tag.titleText ?? "");
          if (!titleText) return;
          clearAllChildTags(tag.id);
          updateNode({ ...tag, titleText });
        }}
      >
        <MdEdit className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
      <button
        className="cursor-pointer lg:opacity-0 group-hover:opacity-100 transition-all"
        data-testid={DataTest.DeleteTag}
        onClick={async (evt) => {
          if (params.tagId !== tag.id) {
            evt.preventDefault();
          }
          const confirmed = confirm("Are you sure?");
          if (!confirmed) return;
          await deleteNodeAndChildren(tag);
          const parentTags = await getParentNodes(params.tagId!);
          if (parentTags.some(({ deleted }) => deleted)) {
            navigate("/");
          }
        }}
      >
        <MdClose className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
    </div>
  );
}
