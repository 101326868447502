import { useCallback, useEffect, useRef, useState } from "react";
import { DataTest } from "../../tests/e2e/utils/constants";
import {
  NodesGroupedByPage,
  NodesGroupedByPageSkeleton,
} from "./NodesGroupedByPage";
import { PaginationComponent } from "./Pagination/PaginationComponent";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown";
import { Button } from "./ui/button";
import { cn } from "@/utils/styles";
import { useSearch } from "@/context/SearchContext";
import { ListOrder } from "@/types";
import { ChronologicalNodes } from "./ChronologicalNodes";
import { MobileFilterDrawer } from "./SearchFilter/MobileFilterDrawer/MobileFilterDrawer";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { btn } from "@/styles/classes";
import clsx from "clsx";
import { PlusIcon, XIcon } from "lucide-react";
import { AiOutlineSearch } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { SearchFilterIcon } from "./Icons";
import { ListOrderMenu } from "./ListOrder";
import { SearchFilters } from "./SearchFilter/SearchFilters";
import { useMenu } from "@/context/MenuContext";

export function SearchRoute() {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const {
    setNodesPerPage,
    setCurrentPage,
    currentPage,
    loading,
    cleanFilters,
    nodesInfo,
    nodesPerPage,
    fetchNodes,
    setCursors,
    listOrder,
  } = useSearch();

  const { nodes = [], hasNextPage = false, totalNodes = 0 } = nodesInfo;

  const onChangeNodesPerPage = (nodesToFetch: number) => {
    fetchNodes(1, nodesToFetch, undefined, true);
    setNodesPerPage(nodesToFetch);
    setCurrentPage(1);
    setCursors({
      0: "",
      1: "",
    });
  };

  useEffect(() => {
    fetchNodes(1);
    setCurrentPage(1);
    setCursors({
      0: "",
      1: "",
    });
  }, [cleanFilters]);

  return (
    <>
      <TopBar />
      <div
        ref={scrollRef}
        className="overflow-auto p-2 h-full"
        style={{
          overflowAnchor: "none",
        }}
      >
        <div className="max-w-4xl m-auto w-full">
          {loading && <NodesGroupedByPageSkeleton length={20} />}
          {!loading && nodes.length === 0 && <div>No results</div>}
          {!loading && nodes.length !== 0 && (
            <div>
              {listOrder === ListOrder.Chronological && (
                <ChronologicalNodes nodes={nodes} />
              )}
              {listOrder === ListOrder.ByPage && (
                <NodesGroupedByPage
                  nodes={nodes}
                  data-testid={DataTest.GroupedNodesList}
                  showSkeleton
                />
              )}
              <DropdownMenu>
                <DropdownMenuTrigger asChild className="mt-4">
                  <Button variant="outline">
                    Rows Per Page: {nodesPerPage}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  {[100, 200, 500, 1000].map((count) => (
                    <DropdownMenuItem
                      key={count}
                      onClick={() => onChangeNodesPerPage(count)}
                      className={cn({
                        "bg-accent": nodesPerPage === count,
                      })}
                    >
                      <span>{count}</span>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
              <PaginationComponent
                totalCount={totalNodes}
                perPage={nodesPerPage}
                onClickNext={(_, val) => {
                  setCurrentPage(val);
                  fetchNodes(val, undefined, undefined, true);
                  if (scrollRef.current) scrollRef.current.scrollTop = 0;
                }}
                onClickPrev={(_, val) => {
                  setCurrentPage(val);
                  fetchNodes(val, undefined, undefined, true);
                  if (scrollRef.current) scrollRef.current.scrollTop = 0;
                }}
                page={currentPage}
                showCount={true}
                disableNext={!hasNextPage}
                disablePrev={currentPage === 1}
              />
            </div>
          )}
        </div>
        {isMobile && <MobileFilterDrawer />}
      </div>
    </>
  );
}

const TopBar = () => {
  const { setIsMenuOpen } = useMenu();
  const {
    showFilters,
    setShowFilters,
    fetchNodes,
    searchValue,
    setSearchValue,
    setCurrentPage,
    setCursors,
    listOrder,
    setListOrder,
    setMobileFilterState,
    filters,
  } = useSearch();

  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilters = useCallback(
    debounce((searchValue: string) => {
      setSearchParams({ query: searchValue });
    }, 300),
    []
  );

  const search = (forceValue?: string) => {
    // Flush the debounce
    updateFilters.flush();
    // Fetch the nodes after new state is set
    fetchNodes(
      1,
      undefined,
      forceValue !== undefined ? forceValue : searchValue
    );
    setCurrentPage(1);
    setCursors({
      0: "",
      1: "",
    });
  };

  const onChangeListOrder = (listOrderValue: ListOrder) => {
    setListOrder(listOrderValue);
  };

  useEffect(() => {
    const searchParam = searchParams.get("query");
    if (searchParam !== searchValue) {
      if (!searchParam) setSearchParams({ query: searchValue });
      else if (!searchValue) {
        setSearchValue(searchParam);
      }
    }
    search(searchParam || searchValue);
  }, []);

  return (
    <div
      className={cn("flex flex-col gap-2 p-2", isMobile ? "w-full" : "flex-1")}
    >
      <div className="flex flex-rowmt-4 gap-2">
        <button
          type="button"
          className={clsx("lg:hidden", btn, "!p-1 bg-transparent")}
          onClick={() => setIsMenuOpen(true)}
        >
          <GiHamburgerMenu />
        </button>
        <div className="flex flex-1 gap-2 items-center justify-start w-full">
          <div className="flex flex-1 gap-2 items-center w-full relative">
            <AiOutlineSearch className="pl-2 w-7 h-7 text-gray-400 absolute text-center" />
            <input
              type="text"
              name="query"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                updateFilters(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  search();
                  const active = document.activeElement;
                  if (active instanceof HTMLElement) active.blur();
                }
              }}
              onBlur={() => search()}
              data-testid={DataTest.SearchBar}
              className={clsx(
                "flex-1 h-[40px] px-3 py-1 text-lg rounded-lg outline-none border border-slate-200 focus:border-blue-600 pl-10 pr-10"
              )}
            />
            {searchValue.length > 0 && (
              <XIcon
                className="pr-2 w-7 h-7 text-gray-400 absolute text-center right-0"
                onClick={() => {
                  setSearchValue("");
                  search("");
                }}
              />
            )}
          </div>

          {isMobile && (
            <div className="relative">
              <Button
                variant="outline"
                size="icon"
                className="flex items-center justify-center h-[40px] w-[40px] rounded-sm"
                onClick={() => {
                  setMobileFilterState({ open: true });
                }}
              >
                <SearchFilterIcon />
              </Button>
              {filters.rules.length > 0 && (
                <span className="absolute top-1 right-1 transform translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-4 h-4 bg-red-500 text-white text-xs rounded-full">
                  {filters.rules.length}
                </span>
              )}
            </div>
          )}
          {!isMobile && (
            <>
              <Button
                variant="outline"
                className="flex flex-row gap-1 h-[31px]"
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                {!showFilters && (
                  <PlusIcon height={10} width={10} color="#8F98A6" />
                )}
                <span className="text-gray-500">
                  {showFilters ? "Hide Filters" : "Filter"}
                </span>
              </Button>
              <ListOrderMenu
                listOrderOptions={Object.entries(ListOrder)
                  .filter(([_, val]) => val !== ListOrder.ByPriority)
                  .map(([_, val]) => val)}
                listOrder={listOrder}
                onChangeListOrder={onChangeListOrder}
              />
            </>
          )}
        </div>
      </div>
      {!isMobile && showFilters && <SearchFilters />}
    </div>
  );
};
