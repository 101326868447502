import { db } from "../db";

export async function findNodeDescendents(nodeId: string) {
  const result = [nodeId];
  const children = await db.nodes.where("parentId").equals(nodeId).toArray();

  for (const child of children) {
    const descendents = await findNodeDescendents(child.id);
    result.push(...descendents);
  }

  return result;
}
