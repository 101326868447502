import { Editor } from "@tiptap/react";
import { db } from "../../db";
import {
  createNode,
  deleteNode,
  memoizedGenerateJSONForNewTipTap,
  updateNode,
} from "../../utils";
import { HistoryManager } from "../../hooks/useHistoryManager";
import { EfNode } from "../../types";
import { focusEditor, getEditorsMap } from "./selectionUtils";
import {
  canLiftNode,
  canSinkNode,
  liftNode,
  sinkNode,
} from "../VirtualizedEditor/editorUtils/liftAndSink";
import invariant from "tiny-invariant";
import { extractIds } from "@/utils/extractIds";

export interface SelectionKeyHandlerParams {
  keyDownEvent: KeyboardEvent;
  nodesPromise: Promise<readonly [EfNode, EfNode[], EfNode | undefined]>;
  history: HistoryManager;
}
type GenericKeyHandlerParams = Omit<
  SelectionKeyHandlerParams,
  "keyDownEvent"
> & { key: string };

export const handleGenericKey = async ({
  key,
  nodesPromise,
  history,
}: GenericKeyHandlerParams) => {
  const [firstNode, middleNodes, lastNode] = await nodesPromise;
  const firstEditor = getEditorsMap()[firstNode.id];
  return history.run({
    redo: async () => {
      await db.transaction("rw", db.nodes, async () => {
        const contentText = `<p>${key}</p>`;
        const json = memoizedGenerateJSONForNewTipTap(contentText);
        await updateNode({
          ...firstNode,
          contentText,
          tagIds: extractIds(json, "tag"),
          mentionIds: extractIds(json, "mention"),
          referencedPageIds: extractIds(json, "PageRef"),
          fileIds: extractIds(json, "file"),
        });

        // Delete all middle nodes
        if (middleNodes.length) {
          await Promise.all(middleNodes.map(deleteNode));
        }

        // Delete last node as well
        if (lastNode) {
          await deleteNode(lastNode);
        }

        firstEditor && focusEditor(firstEditor);
      });
    },
    undo: async () => {
      await db.transaction("rw", db.nodes, async () => {
        // This runs in a tr on history
        // Restore first node
        await updateNode(firstNode);
        // Restore center nodes
        for (const node of middleNodes) {
          await createNode(node);
        }
        // Restore final node
        if (lastNode) {
          await createNode(lastNode);
        }
      });
    },
  });
};

const getTopNodes = (nodes: EfNode[]): EfNode[] => {
  const nodeMap = new Map<string, EfNode>();

  // Map all nodes by their ID for quick lookup
  nodes.forEach((node) => {
    nodeMap.set(node.id, node);
  });

  // Filter nodes to find top nodes (nodes whose parents are not in the array)
  const topNodes = nodes.filter((node) => {
    return !node.parentId || !nodeMap.has(node.parentId);
  });

  return topNodes;
};
export const handleSelectionSink = async ({
  nodesPromise,
  history,
}: GenericKeyHandlerParams) => {
  const [firstNode, middleNodes, lastNode] = await nodesPromise;
  const firstEditor = getEditorsMap()[firstNode.id];
  const allNodes = lastNode
    ? [firstNode, ...middleNodes, lastNode]
    : [firstNode, ...middleNodes];

  await history.run({
    redo: async () => {
      // We need to get topmost layers
      const topNodes = getTopNodes(allNodes);

      await db.transaction("rw", db.nodes, async () => {
        // If we can't sink the first node don't sync at all
        const canSink = await canSinkNode(topNodes[0]);
        if (canSink) {
          // Get all top layer nodes
          for (const node of topNodes) {
            await sinkNode(node);
          }
        }

        // Focus on first node
        firstEditor && focusEditor(firstEditor);
      });
    },
    undo: async () => {
      await db.transaction("rw", db.nodes, async () => {
        // This runs in a tr on history
        await Promise.all(allNodes.map(updateNode));
      });
    },
  });
};

export const handleSelectionLift = async ({
  nodesPromise,
  history,
}: GenericKeyHandlerParams) => {
  const [firstNode, middleNodes, lastNode] = await nodesPromise;
  const firstEditor = getEditorsMap()[firstNode.id];
  const allNodes = lastNode
    ? [firstNode, ...middleNodes, lastNode]
    : [firstNode, ...middleNodes];

  history.run({
    redo: async () => {
      // We need to get topmost layers
      const topNodes = getTopNodes(allNodes);
      const topNodeIDs = topNodes.map((node) => node.id);

      await db.transaction("rw", db.nodes, async () => {
        // If we can't lift the first node don't sync at all
        const canLift = await canLiftNode(topNodes[0]);
        if (canLift) {
          for (const nodeID of topNodeIDs) {
            // After a previous node has been lifted it might have changes the current nodes position
            // So we must requery the node
            const node = await db.nodes.get(nodeID);
            invariant(node, "Node must exist");
            await liftNode(node);
          }
        }

        // Focus on first node
        firstEditor && focusEditor(firstEditor);
      });
    },
    undo: async () => {
      await db.transaction("rw", db.nodes, async () => {
        // This runs in a tr on history
        await Promise.all(allNodes.map(updateNode));
      });
    },
  });
};
