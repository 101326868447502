import { liveQuery } from "dexie";
import { generateKeyBetween } from "fractional-indexing";
import { debounce, isNil } from "lodash";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { v4 } from "uuid";
import { db } from "../../db";
import { EfNodeType } from "../../graphql";
import { useHistoryManager } from "../../hooks/useHistoryManager";
import { useIosKeyboardHeight } from "../../hooks/useIosKeyboardHeight";
import { useProxyRef } from "../../hooks/useProxyRef";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { EfNode, FocusModeType } from "../../types";
import { createNode, deleteNode } from "../../utils";
import { EditorContextMenuWrapper } from "../ContentMenuWrapper/EditorContextMenuWrapper";
import { NewNodeRenderer } from "../NewNodeRenderer";
import {
  SelectionWrapper,
  useAreaSelection,
} from "../SelectionWrapper/SelectionWrapper";
import { Title } from "../Title";
import {
  BOTTOM_TOOLBAR_HEIGHT,
  MobileToolbar,
} from "./MobileToolbar/MobileToolbar";
import { onLift, onSink } from "./editorUtils/liftAndSink";
import { ConfirmPastePopup, usePastePopup } from "../ConfirmPastePopup";
import {
  onArrowDown,
  onArrowLeft,
  onArrowRight,
  onArrowUp,
} from "./editorUtils/cursor";
import { onFocus } from "./editorUtils/focus";
import { onBlur } from "./editorUtils/blur";
import { onCreate } from "./editorUtils/create";
import {
  onDeleteBackwards,
  onDeleteForwards,
} from "./editorUtils/joinAndSplit";
import { onSelectBackward, onSelectForward } from "./editorUtils/selection";
import { onPaste } from "./editorUtils/paste";
import { onToggleTask } from "./editorUtils/task";

import {
  getPagePropertiesInSettings,
  setLastVisitedInSettings,
} from "@/utils/settings";
import { useLiveQuery } from "dexie-react-hooks";
import { cn } from "@/utils/styles";
import { FocusModeContext } from "@/context/FocusMode";
import { TitleContext } from "@/context/TitleContext";
import { getEditorsMap } from "../SelectionWrapper/selectionUtils";
import { isKeyboardHidden, scrollNodeAboveKeyboard } from "./mobileUtils";
import { getEditorId, getNodeIdFromEditorId } from "./editorUtils/genericUtils";
import { onUpdate } from "./editorUtils/update";
export type VirtualizedEditorState = Awaited<ReturnType<typeof querier>>;

export function VirtualizedEditor({
  initialCursor,
  pathPrefix,
  parentNode,
  disableTitleEdit,
  nodeParam,
}: {
  initialCursor: string;
  pathPrefix: string;
  parentNode: EfNode;
  disableTitleEdit?: boolean;
  nodeParam?: string; // param coming from IDB or url and for the first mount focus will come to this node if present else focus is at the top
}) {
  const [cursor, setCursor] = useState<string>(initialCursor);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [state, setState] = useState<VirtualizedEditorState>();
  const [selectMode] = useState(false);
  const { focusModeDetails, setFocusModeDetails } =
    useContext(FocusModeContext);

  const { showInTopBar, setShowInTopBar } = useContext(TitleContext);

  const focusModeConfig = useLiveQuery(() => getPagePropertiesInSettings(), []);

  // Context menu
  const [selectedContextMenuNode, setSelectedContextMenuNode] = useState<
    string | undefined
  >(undefined);

  // Confirm Paste
  const { showConfirmPaste, setShowConfirmPaste, onSubmitPasteRef } =
    usePastePopup();

  const iosKeyboardHeight = useIosKeyboardHeight();

  const stateRef = useProxyRef(state);
  const nodeParamProxy = useProxyRef(nodeParam);
  const focusModeDetailsProxy = useProxyRef(focusModeDetails);

  const stateSetCountRef = useRef<number>(0);
  const mobileToolbarRef = useRef<HTMLDivElement>(null);
  const firstCommonEditorRef = useRef<string | undefined>();
  const nodeTopRef = useRef<number | undefined>(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const focusedNodeRef = useRef<HTMLElement | undefined>();

  const someNodeIsFocused = currentId !== null;
  const [searchParams, setSearchParams] = useSearchParams();
  // this is used just to highlight the node to focus only when url param is present.
  const nodeUrlParam = useMemo(() => searchParams.get("node"), []);
  const history = useHistoryManager({ someNodeIsFocused });
  const areaSelection = useAreaSelection(scrollRef, state);
  const observer = useResizeObserver(async () => {
    /**
     * https://github.com/execfn/exec-world/pull/304/
     *
     * This change is for 1 purpose
     * 1. Scroll anchoring which is not supported by safari in IOS and desktop
     *
     * we have 2 cases here
     * 1. When we are loading page for the first time then we have use query param in url then we are scrolling that element to the toip of the view
     * 2. after subsequent scoll to load new page we are putting first common node back to its position.
     *
     */
    if (!focusModeDetailsProxy.current.activated) {
      if (
        scrollRef.current &&
        stateSetCountRef.current === 1 &&
        nodeParamProxy.current
      ) {
        // case when query param is present then scroll into the view of that node.
        const cursorNode = stateRef.current?.nodes.find(
          (node) => node.id === getNodeIdFromEditorId(nodeParamProxy.current)
        );
        if (!cursorNode) {
          return;
        }
        const cursorNodeElement = scrollRef.current.querySelector(
          `[data-nodeid="${nodeParamProxy.current}"]`
        );
        if (cursorNode && cursorNodeElement) {
          cursorNodeElement?.scrollIntoView({
            behavior: "instant",
            block: "start",
          });
        }
      } else {
        // case when loading next time
        if (isMobile) {
          let keyboardHiding = false;
          if (
            scrollRef.current &&
            focusedNodeRef.current &&
            window.lastIosKeyboardHeight
          ) {
            // Check if the keyboard is hiding the cursor in the next scroll top
            keyboardHiding = isKeyboardHidden(
              scrollRef.current,
              focusedNodeRef.current,
              -(nodeTopRef.current || 0)
            );
          }
          if (!keyboardHiding) {
            // Only update scroll top if it is not hiding
            setNodeScrollTop(firstCommonEditorRef.current, nodeTopRef.current);
          }
        } else {
          setNodeScrollTop(firstCommonEditorRef.current, nodeTopRef.current);
        }
      }
    }
  });

  useEffect(() => {
    const createNodeIfRequired = async () => {
      const nodes = await db.nodes
        .where("[computed.visible+computed.pathInPage]")
        .between(
          [1, parentNode.computed.pathInPage],
          [1, `${parentNode.computed.pathInPage}\uffff`]
        )
        .filter(({ nodeType }) =>
          [EfNodeType.Task, EfNodeType.Block].includes(nodeType)
        )
        .toArray();
      if (!nodes?.length) {
        await createNode({
          id: v4(),
          parentId: parentNode.id,
          position: generateKeyBetween(null, null),
          nodeType: EfNodeType.Block,
          properties: {},
          tagIds: [],
          referencedPageIds: [],
          mentionIds: [],
          fileIds: [],
          titleText: "",
          contentText: "<p></p>",
        });
      }
    };
    createNodeIfRequired();

    // When spacebar is pressed and focus goes to document body then page will scroll thus preventing the default behaviour in this case.
    const keyDownHandeler = (event: KeyboardEvent) => {
      if (event.code == "Space" && event.target == document.body) {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", keyDownHandeler);
    return () => {
      setFocusModeDetails({
        activated: false,
        firstUnFocusedNode: undefined,
      });
      setShowInTopBar(false);
      window.removeEventListener("keydown", keyDownHandeler);
    };
  }, []);

  useEffect(() => {
    const observable = liveQuery(() => querier(cursor, pathPrefix));
    const subscription = observable.subscribe((newState) => {
      const firstCommonNode = stateRef.current?.nodes.find((node) =>
        newState.nodes.some((newNode) => newNode.id === node.id)
      );
      setState(newState);
      stateSetCountRef.current += 1;
      firstCommonEditorRef.current = getEditorId(
        parentNode.id,
        firstCommonNode?.id!
      );
      nodeTopRef.current = getNodeScrollTop(firstCommonEditorRef.current);
    });
    return () => subscription.unsubscribe();
  }, [cursor, pathPrefix, stateRef]);

  // load more when first node is focused
  useEffect(() => {
    if (!currentId) return;
    const firstNode = state?.nodes[0];
    if (!firstNode) return;
    if (firstNode.id === currentId && state.hasMoreBefore) {
      invariant(firstNode.computed.pathInPage);
      setCursor(firstNode.computed.pathInPage);
    }
  }, [currentId, state?.nodes, state?.hasMoreBefore]);

  // load more when last node is focused
  useEffect(() => {
    if (!currentId) return;
    const lastNode = state?.nodes[state.nodes.length - 1];
    if (!lastNode) return;
    if (lastNode.id === currentId && state.hasMoreAfter) {
      invariant(lastNode.computed.pathInPage);
      setCursor(lastNode.computed.pathInPage);
    }
  }, [currentId, state?.nodes, state?.hasMoreAfter]);

  const loadMoreBefore = () => {
    if (!state?.hasMoreBefore) return;
    const firstNode = state.nodes[0];
    invariant(firstNode.computed.pathInPage);
    if (firstNode.computed.pathInPage === cursor) return;
    console.log("load before", firstNode.contentText);
    setCursor(firstNode.computed.pathInPage);
  };

  const loadMoreAfter = () => {
    if (!state?.hasMoreAfter) return;
    const lastNode = state.nodes[state.nodes.length - 1];
    invariant(lastNode.computed.pathInPage);
    if (lastNode.computed.pathInPage === cursor) return;
    console.log("load after", lastNode.contentText);
    setCursor(lastNode.computed.pathInPage);
  };

  const getNodeScrollTop = (editorId?: string) => {
    if (!scrollRef.current || !editorId) return;
    const nodeElement = scrollRef.current?.querySelector(
      `[data-nodeid="${editorId}"]`
    );
    if (!nodeElement) return;
    return (nodeElement as HTMLElement).offsetTop - scrollRef.current.scrollTop;
  };

  const setNodeScrollTop = (editorId?: string, nodeTop?: number) => {
    if (!scrollRef.current || !editorId || isNil(nodeTop)) return;
    const nodeElement = scrollRef.current?.querySelector(
      `[data-nodeid="${editorId}"]`
    );
    if (!nodeElement) return;
    scrollRef.current.scrollTo(
      0,
      (nodeElement as HTMLElement).offsetTop - nodeTop
    );
  };

  const updateNodeParamAndFirstCommonRef = useMemo(
    () =>
      debounce(() => {
        const nodeInViewPort = getFirstNodeInViewPort();
        if (!nodeInViewPort) {
          return;
        }
        setLastVisitedInSettings(parentNode.id, [nodeInViewPort]);
        setSearchParams(
          { node: getNodeIdFromEditorId(nodeInViewPort)! },
          { replace: true }
        );
        // Added below because when user scroll we need to set firstCommonNodeRef to the first node in view port
        // because when user presses enter or shift + enter which causes change in height and it resizeObserver will re-focus to old firstCommonNode
        firstCommonEditorRef.current = nodeInViewPort;
        nodeTopRef.current = getNodeScrollTop(firstCommonEditorRef.current);
      }, 500),
    []
  );

  const getFirstNodeInViewPort = useCallback(() => {
    const nodeElements =
      scrollRef.current?.querySelectorAll<HTMLElement>(`[data-nodeid]`);
    if (!nodeElements) return;
    const firstNodeInViewport = Array.from(nodeElements).find(
      (nodeElement) =>
        nodeElement.offsetTop +
          (nodeElement.getBoundingClientRect?.()?.height || 0) >=
        (scrollRef.current?.scrollTop ?? 0)
    );
    if (!firstNodeInViewport) return;
    return firstNodeInViewport.getAttribute("data-nodeid");
  }, []);

  const moveToFirstNodeInViewPort = useCallback(() => {
    const nodeId = getNodeIdFromEditorId(getFirstNodeInViewPort());
    if (!nodeId) return;
    const firstNodeEditor = getEditorsMap()[nodeId];
    if (firstNodeEditor) {
      firstNodeEditor.commands.focus();
      setCurrentId(nodeId);
    }
  }, [getFirstNodeInViewPort, setCurrentId]);

  const jumpToTop = async (node: EfNode) => {
    if (focusModeDetails.activated) {
      setFocusModeDetails({
        activated: false,
        firstUnFocusedNode: undefined,
      });
      return;
    }
    const nodes = await db.nodes
      .where("[computed.visible+computed.pathInPage]")
      .above([1, `/null:${parentNode.id}`])
      .limit(2)
      .toArray();

    // when there is no contentText in first node then firstUnFocusedNode is secondNode
    // when there is contentText in first node then firstUnFocusedNode is firstNode
    const firstNode = nodes[0];
    const secondNode = nodes?.[1];
    if (!firstNode) return;
    if (firstNode.contentText === "" || firstNode.contentText === "<p></p>") {
      // first node is empty, just focus it
      history.run({
        redo: () => {
          setCurrentId(firstNode.id);
          setCursor(firstNode.computed.pathInPage!);
        },
        undo: () => {
          setCurrentId(node.id);
          setCursor(node.computed.pathInPage!);
        },
      });
      firstCommonEditorRef.current = getEditorId(parentNode.id, firstNode.id);
      nodeTopRef.current = 0;
      setFocusModeDetails({
        activated: true,
        firstUnFocusedNode: secondNode,
      });
    } else {
      // first node is not empty, create new node above it
      const id = v4();
      history.run({
        redo: async () => {
          await createNode({
            id,
            parentId: parentNode.id,
            position: generateKeyBetween(null, firstNode.position),
            nodeType: EfNodeType.Block,
            properties: {},
            tagIds: [],
            referencedPageIds: [],
            titleText: "",
            contentText: "",
          });
          setCurrentId(id);
          const firstCreatedNode = await db.nodes
            .where("id")
            .equals(id)
            .first();
          setCursor(firstCreatedNode?.computed.pathInPage!);
        },
        undo: () => {
          setCurrentId(node.id);
          setCursor(node.computed.pathInPage!);
          deleteNode({ id });
        },
      });
      firstCommonEditorRef.current = getEditorId(parentNode.id, id);
      nodeTopRef.current = 0;
      setFocusModeDetails({
        activated: true,
        firstUnFocusedNode: firstNode,
      });
    }
  };

  if (!state) return null;

  return (
    <>
      <div
        ref={scrollRef}
        className="overflow-auto flex-1 relative px-2 overscroll-none mt-5"
        id="scroll-element"
        style={{
          overflowAnchor: "none",
          paddingBottom: iosKeyboardHeight // On mobile we must add padding of keyboard height for scroll
            ? iosKeyboardHeight + BOTTOM_TOOLBAR_HEIGHT
            : undefined,
        }}
        onScroll={() => {
          // Update node rects
          areaSelection.onContainerScroll();
          updateNodeParamAndFirstCommonRef();
          if (!showInTopBar && (scrollRef.current?.scrollTop || 0) > 4) {
            setShowInTopBar(true);
          }
          if (
            !state.hasMoreBefore &&
            (scrollRef.current?.scrollTop || 0) <= 4
          ) {
            setShowInTopBar(false);
          }
          const threshold = 5;

          setTimeout(() => {
            if (!scrollRef.current) {
              return;
            }

            if (
              state.hasMoreBefore &&
              scrollRef.current.scrollTop < threshold
            ) {
              loadMoreBefore();
            }

            if (
              state.hasMoreAfter &&
              scrollRef.current.scrollHeight -
                scrollRef.current.scrollTop -
                scrollRef.current.clientHeight <
                threshold
            ) {
              loadMoreAfter();
            }
          }, 1);
        }}
      >
        {!state.hasMoreBefore && (
          <div
            className={cn("max-w-[95%] sm:max-w-[74%] mx-auto", {
              "opacity-0": focusModeDetails?.activated || showInTopBar,
            })}
          >
            <Title
              page={parentNode}
              disableEdit={disableTitleEdit}
              moveToFirstNodeInViewPort={moveToFirstNodeInViewPort}
              history={history}
            />
          </div>
        )}
        <EditorContextMenuWrapper
          nodeId={selectedContextMenuNode}
          setNodeId={setSelectedContextMenuNode}
        >
          <SelectionWrapper
            history={history}
            virtualEditorState={state}
            areaSelection={areaSelection}
            scrollRef={scrollRef}
            rootNodeId={parentNode.id}
          >
            <div
              className="max-w-[95%] sm:max-w-[74%] mx-auto mb-4"
              ref={observer}
            >
              {state.nodes.map((node, index, nodes) => (
                <NewNodeRenderer
                  key={node.id}
                  rootNodeId={parentNode.id}
                  history={history}
                  node={node}
                  focused={node.id === currentId}
                  focusModeType={
                    focusModeConfig?.[parentNode.id]?.focusMode.type ||
                    FocusModeType.DIM_OTHER_BLOCKS
                  }
                  highlighted={nodeUrlParam === node.id}
                  onArrowUp={onArrowUp(
                    areaSelection.clearSelectedNodes,
                    nodes,
                    index,
                    setCurrentId
                  )}
                  onArrowDown={onArrowDown(
                    areaSelection.clearSelectedNodes,
                    nodes,
                    index,
                    setCurrentId
                  )}
                  onArrowLeft={onArrowLeft(areaSelection.clearSelectedNodes)}
                  onArrowRight={onArrowRight(areaSelection.clearSelectedNodes)}
                  onFocus={(e) => {
                    focusedNodeRef.current = e.target as HTMLElement;
                    onFocus(
                      node,
                      scrollRef,
                      setCurrentId,
                      iosKeyboardHeight,
                      focusModeDetails,
                      getFirstNodeInViewPort,
                      setFocusModeDetails,
                      firstCommonEditorRef,
                      nodeTopRef,
                      getNodeScrollTop,
                      parentNode.id
                    )(e);
                  }}
                  onUpdate={(nodeElement) => {
                    onUpdate(nodeElement, scrollRef.current, iosKeyboardHeight);
                  }}
                  onBlur={onBlur(mobileToolbarRef)}
                  onCreate={onCreate(history, node, setCurrentId)}
                  onEscape={() => jumpToTop(node)}
                  onDeleteBackward={onDeleteBackwards(
                    history,
                    nodes,
                    node,
                    index,
                    setCurrentId
                  )}
                  onDeleteForward={onDeleteForwards(
                    history,
                    nodes,
                    node,
                    index,
                    setCurrentId
                  )}
                  onSink={onSink(history, node, setCurrentId)}
                  onLift={onLift(history, node, setCurrentId)}
                  selectMode={selectMode}
                  isAreaSelection={
                    areaSelection.isAreaSelectionActive() &&
                    areaSelection.areaSelectedBounds.anchorNode !==
                      areaSelection.areaSelectedBounds.currentNode
                  }
                  selected={
                    areaSelection.isNodeSelected(node) ||
                    node.id === selectedContextMenuNode
                  }
                  focusModeDetails={focusModeDetails}
                  getPreviousNodeTagIds={() => {
                    return nodes[index - 1]?.tagIds ?? [];
                  }}
                  showConfirmPastePopup={(onSubmit) => {
                    setShowConfirmPaste(true);
                    onSubmitPasteRef.current = onSubmit;
                  }}
                  onSelectForward={onSelectForward(
                    nodes,
                    node,
                    areaSelection,
                    index,
                    setCurrentId,
                    areaSelection.selectNode
                  )}
                  onSelectBackward={onSelectBackward(
                    nodes,
                    node,
                    areaSelection,
                    index,
                    setCurrentId,
                    areaSelection.selectNode
                  )}
                  toggleTask={onToggleTask(node, history)}
                  onPaste={onPaste(history, node, setCurrentId)}
                />
              ))}
            </div>
          </SelectionWrapper>
        </EditorContextMenuWrapper>
      </div>
      {isMobile && (
        <MobileToolbar
          state={state}
          currentId={currentId}
          setCurrentId={setCurrentId}
          historyManager={history}
          toolbarRef={mobileToolbarRef}
          jumpToTop={jumpToTop}
        />
      )}
      <ConfirmPastePopup
        isOpen={showConfirmPaste}
        onSubmit={(pasteAsList) => {
          if (onSubmitPasteRef.current) {
            onSubmitPasteRef.current(pasteAsList);
          }
          setShowConfirmPaste(false);
          onSubmitPasteRef.current = null;
        }}
      />
    </>
  );
}

// limit based on screen height - based on ratio of 80 nodes for 600px height - makes sure it is even
// we need this calculation because if the limit is too low we will enter an infinite loop of loading more and less nodes
const limit = Math.round((screen.height * 80) / 600 / 2) * 2;

async function querier(cursor: string, pathPrefix: string) {
  // sleep 2 seconds
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  const filterNodes = (node: EfNode) => {
    return [EfNodeType.Block, EfNodeType.Task].includes(node.nodeType);
  };
  let countAfter = 0;
  let countBefore = 0;
  const [nodesBefore, nodesAfter] = await Promise.all([
    db.nodes
      .where("[computed.visible+computed.pathInPage]")
      .belowOrEqual([1, cursor])
      .until((item) => {
        if (![EfNodeType.Block, EfNodeType.Task].includes(item.nodeType)) {
          return false;
        }
        countBefore++;
        return (
          !item.computed.pathInPage?.startsWith(pathPrefix) ||
          limit + 2 === countBefore
        );
      })
      .filter(filterNodes)
      .reverse()
      .toArray(),
    db.nodes
      .where("[computed.visible+computed.pathInPage]")
      .above([1, cursor])
      .until((item) => {
        if (![EfNodeType.Block, EfNodeType.Task].includes(item.nodeType)) {
          return false;
        }
        countAfter++;
        return (
          !item.computed.pathInPage?.startsWith(pathPrefix) ||
          limit + 2 === countAfter
        );
      })
      .filter(filterNodes)
      .toArray(),
  ]);

  const nodesBeforeLimit = Math.min(
    nodesBefore.length,
    limit,
    limit / 2 + Math.max(limit / 2 - nodesAfter.length, 0)
  );
  const nodesAfterLimit = Math.min(
    nodesAfter.length,
    limit,
    limit / 2 + Math.max(limit / 2 - nodesBefore.length, 0)
  );

  const hasMoreBefore = nodesBefore.length > nodesBeforeLimit;
  const hasMoreAfter = nodesAfter.length > nodesAfterLimit;

  const nodes = [
    ...nodesBefore.slice(0, nodesBeforeLimit).reverse(),
    ...nodesAfter.slice(0, nodesAfterLimit),
  ];

  return { nodes, hasMoreBefore, hasMoreAfter, cursor };
}
