import { Combobox, Dialog } from "@headlessui/react";
import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { HiHashtag } from "react-icons/hi";
import { EfNodeType } from "../../graphql";
import { EfNode, EfNodeData } from "../../types";
import { Dictionary, camelCase, debounce, groupBy, upperCase } from "lodash";
import { MdOutlinePersonOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaRegFileAlt } from "react-icons/fa";

import { db } from "../../db";
import { sortNodesBasedOnRecency } from "../../utils/tags";
import { useKeyboardShortcut } from "../../hooks/useKeyboardShortcut";
import { isMacOs } from "react-device-detect";
import { getTagWithFullNameCached } from "../../utils/getTagWithFullName";
import { filterNodes, getUpdatedQuery } from "./SearchUtil";

type GlobalSearchProps = {
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const GlobalSearch = ({ isOpen, setIsOpen }: GlobalSearchProps) => {
  const [nodesByType, setNodesByType] = useState<
    Dictionary<Partial<EfNodeData>[]>
  >({});
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const { isKeyboardShortcut } = useKeyboardShortcut({
    keys: [{ or: isMacOs ? ["cmd"] : ["ctrl"] }, { or: ["k", "K"] }],
    element: window,
    preventDefault: true,
  });

  useEffect(() => {
    if (isKeyboardShortcut) {
      setIsOpen?.(true);
    }
  }, [isKeyboardShortcut]);

  useEffect(() => {
    if (!isOpen) {
      setNodesByType({});
      setLoading(false);
    }
  }, [isOpen]);

  const getQueryData = useCallback(async (query: string) => {
    const nodesWithoutTask = await db.nodes
      .where("nodeType")
      .anyOf([
        EfNodeType.Tag,
        EfNodeType.Page,
        EfNodeType.Contact,
        EfNodeType.ThoughtPad,
      ])
      .toArray();
    const nodes = nodesWithoutTask.concat([
      {
        titleText: "Pending",
        nodeType: EfNodeType.Task,
        deleted: false,
      },
      {
        titleText: "Completed",
        nodeType: EfNodeType.Task,
        deleted: false,
      },
    ] as EfNode[]);
    // Updating titleText for tag to fullName of  tag.
    const filteredNodes = (
      await Promise.allSettled(
        nodes.map(async (node) => {
          if (node.nodeType === EfNodeType.Tag) {
            return {
              ...node,
              titleText: (await getTagWithFullNameCached(node.id)).fullName,
            };
          }
          return node;
        })
      )
    )
      .map((node) => {
        if (node.status === "fulfilled") {
          return node.value;
        }
      })
      .filter((node) => filterNodes(query, node)) as EfNodeData[];
    const sortedNodes = await sortNodes(groupBy(filteredNodes, "nodeType"));
    setNodesByType(sortedNodes);
  }, []);

  const onChangeSearch = useCallback(
    debounce(
      async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(value);
        if (!value) {
          setNodesByType({});
          return;
        }
        setLoading(true);
        await getQueryData(value);
        setLoading(false);
      },
      300
    ),
    [setNodesByType, setLoading]
  );

  const onClickResult = ({
    nodeType,
    node,
  }: {
    nodeType: EfNodeType;
    node: EfNode;
  }) => {
    switch (nodeType) {
      case EfNodeType.ThoughtPad:
        navigate("/thoughtpad");
        break;
      case EfNodeType.Task:
        navigate(`/tasks/${node.titleText?.toLowerCase()}`);
        break;
      default:
        navigate(`/${nodeType.toLowerCase()}s/${node.id}`);
        break;
    }
    setIsOpen?.(false);
  };

  async function sortNodes(nodes: Dictionary<EfNodeData[]>) {
    const entries = Object.entries(nodes);
    let sortedNodes: Record<string, EfNodeData[]> = {};
    for (const [nodeType, nodes] of entries) {
      switch (nodeType) {
        case EfNodeType.Tag:
          sortedNodes[nodeType] = await sortNodesBasedOnRecency(
            nodes,
            "tagIds"
          );
          break;
        case EfNodeType.Contact:
          sortedNodes[nodeType] = await sortNodesBasedOnRecency(
            nodes,
            "mentionIds"
          );
          break;
        default: {
          sortedNodes[nodeType] = nodes;
          break;
        }
      }
    }
    return sortedNodes;
  }

  const onInputKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key?.toLowerCase() === "escape") {
      setIsOpen(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen?.(false);
      }}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed flex bg-white rounded-lg top-28 w-11/12 mx-3.5 sm:top-1/3 sm:left-[25%] sm:w-2/4 ">
        <Dialog.Panel className="w-full">
          <Combobox onChange={onClickResult}>
            <div className="p-4">
              {loading ? (
                <AiOutlineLoading className="w-6 h-6 left-6 top-[20px] animate-spin inline text-gray-400 absolute text-center" />
              ) : (
                <AiOutlineSearch className="pl-2 pt-1 w-8 h-8 text-gray-400 absolute text-center" />
              )}
              <Combobox.Input
                onChange={onChangeSearch}
                autoFocus
                onKeyDown={onInputKeyDown}
                placeholder="Lookup tags, contacts, pages, tasks…"
                className="flex-1 pr-10 py-1 text-md rounded-3xl outline-none border-2 border-slate-300 focus:border-blue-600 w-full pl-10"
              />
            </div>
            <Combobox.Options className="max-h-80 overflow-auto pb-3 space-y-3">
              {Object.values(nodesByType).flatMap((nodes) => nodes)?.length ===
                0 && (
                <div className="block py-2 p-4 border-t-2">
                  No Results found
                </div>
              )}
              {Object.entries(nodesByType)
                .sort(([nodeTypeA], [nodeTypeB]) =>
                  nodeTypeB.localeCompare(nodeTypeA)
                )
                .map(([nodeType, nodes]) => (
                  <div key={nodeType}>
                    <div className="font-semibold text-slate-500 pl-3">
                      {upperCase(camelCase(nodeType))}
                      {nodeType !== EfNodeType.ThoughtPad && "S"}
                    </div>
                    {(nodes || []).map((node) => {
                      const updatedQuery = getUpdatedQuery(query);
                      const { titleText, id } = node;
                      const nodeString = titleText || "";
                      const matchedIndex = nodeString
                        ?.toLowerCase()
                        ?.indexOf(updatedQuery.toLowerCase());
                      let nodeElement = <>{nodeString}</>;
                      if (matchedIndex >= 0) {
                        nodeElement = (
                          <>
                            {nodeString.substring(0, matchedIndex)}
                            <b className="bg-yellow-200">
                              {nodeString.substring(
                                matchedIndex,
                                matchedIndex + updatedQuery.length
                              )}
                            </b>
                            {nodeString.substring(
                              matchedIndex + updatedQuery.length,
                              nodeString.length
                            )}
                          </>
                        );
                      }
                      return (
                        <Combobox.Option key={id} value={{ nodeType, node }}>
                          {({ active }) => (
                            <>
                              <span
                                className={clsx(
                                  "text-md py-2 cursor-pointer text-slate-70 rounded-md flex items-center space-x-1 pl-7",
                                  { ["bg-slate-100"]: active }
                                )}
                              >
                                <span>
                                  {nodeType === EfNodeType.Tag && (
                                    <HiHashtag className="text-base" />
                                  )}
                                  {nodeType === EfNodeType.Contact && (
                                    <MdOutlinePersonOutline className="text-lg" />
                                  )}
                                  {nodeType === EfNodeType.Page && (
                                    <FaRegFileAlt className="text-sm" />
                                  )}
                                </span>
                                <div>{nodeElement}</div>
                              </span>
                            </>
                          )}
                        </Combobox.Option>
                      );
                    })}
                  </div>
                ))}
            </Combobox.Options>
          </Combobox>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
