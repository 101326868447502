import { DOMParser as PMDomParser } from "@tiptap/pm/model";
import * as Sentry from "@sentry/browser";
import { Range as TiptapRange } from "@tiptap/core";
import { Extension } from "@tiptap/react";
import { Plugin, Selection, TextSelection } from "prosemirror-state";
import { Editor } from "@tiptap/core";
import { isMobile } from "react-device-detect";
import { v4 } from "uuid";
import { NewNodeRendererProps } from "../../components/NewNodeRenderer";
import {
  ClipboardEfNode,
  copyNodesToClipboard,
  extractEfNodesFromHTML,
} from "../../utils/copy";
import { insertFileNodeForNewTipTap } from "../../utils/fileUtils";
import { generateNKeysBetween } from "fractional-indexing";
import { EfNodeType } from "../../graphql";
import { memoizedGenerateHTMLForNewTipTap } from "../../utils";
import { extractIds } from "../../utils/extractIds";
import { EfContentWithIds, EfNode } from "../../types";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    CustomExtension: {
      ignoreHistory: () => ReturnType;
      toggleTask: (range: TiptapRange) => ReturnType;
      addFile: (fileNodeID: string) => ReturnType;
      onArrowUp: () => ReturnType;
      onArrowDown: () => ReturnType;
    };
  }
}

const getNodesBottom = (
  editor: Editor,
  options: NewNodeRendererProps,
  range?: Range
): number => {
  if (!range) {
    return 0;
  }
  let { startOffset, startContainer } = range;
  if (startOffset >= startContainer.childNodes.length) {
    return -Infinity;
  }
  // https://github.com/ProseMirror/prosemirror-view/blob/089aa9bd8f261d8181782bc5f8c46c5dc5e11a5f/src/viewdesc.js#L1258
  // the classes are added as a hack for cursor so removing these when checking elements bottom.
  const classesToFilterNode = [
    "ProseMirror-separator", // dummy imag tag added by prosemirror
    "ProseMirror-trailingBreak", // dummy br tag added by prosemirror
    "ProseMirror-widget",
  ];
  let elementAtStartOffset: ChildNode | null = null;
  while (startOffset >= 0) {
    elementAtStartOffset = startContainer.childNodes[startOffset];
    if (
      elementAtStartOffset instanceof Element &&
      elementAtStartOffset.nodeType !== Node.TEXT_NODE
    ) {
      const className = elementAtStartOffset.className;
      // checking if a element doesn't have classNotToMatch.
      if (
        !classesToFilterNode.find((classNames) =>
          className?.includes(classNames)
        )
      ) {
        // if we find a br tag it means that there is a breakline and we should just move to different line.
        if (elementAtStartOffset.tagName.toLocaleLowerCase() === "br") {
          return -Infinity;
        }
        break;
      }
    }
    startOffset--;
  }

  // after traversing array if elementAtStartOffSet comes out to be a text node then create selection and get bottom from it.
  if (elementAtStartOffset?.nodeType === Node.TEXT_NODE) {
    const newRange = document.createRange();
    newRange.selectNode(elementAtStartOffset);
    return Array.from(newRange?.getClientRects() || []).at(-1)?.bottom || 0;
  }
  if (elementAtStartOffset instanceof Element) {
    return (
      Array.from(elementAtStartOffset?.getClientRects() || []).at(-1)?.bottom ||
      0
    );
  }
  // TODO: This is temporary need to remove it once error is found
  console.error(
    "get client rect is not a function: contentHtml:- ",
    editor.getHTML()
  );
  console.error(
    "selection from and to",
    editor.state?.selection?.from,
    editor.state?.selection?.to
  );
  console.error("node id", options.node?.id);
  Sentry.captureException(
    `code should not come here unknown case, nodeTyp:- ${elementAtStartOffset?.nodeType}`
  );
  return -Infinity;
};

export const CustomExtension = Extension.create<NewNodeRendererProps>({
  priority: 0,

  onFocus(e) {
    this.options.onFocus(e.event);
  },
  onBlur(e) {
    this.options.onBlur(e.event);
  },
  addCommands() {
    return {
      toggleTask:
        (range: TiptapRange) =>
        ({ dispatch, editor }) => {
          if (dispatch) {
            this.options.toggleTask(range, editor);
          }
          return true;
        },
      addFile:
        (fileNodeID) =>
        ({ tr, state, dispatch }) => {
          if (!dispatch) return false;
          insertFileNodeForNewTipTap(tr, state, fileNodeID);
          dispatch(tr);
          return true;
        },
      ignoreHistory:
        () =>
        ({ tr, dispatch }) => {
          if (!dispatch) return false;
          dispatch(tr.setMeta("ignoreHistory", true));
          return true;
        },
      onArrowUp:
        () =>
        ({ editor }) => {
          const {
            state: { selection, doc },
          } = editor;
          if (selection.from !== selection.to) {
            editor.commands.setTextSelection(selection.to);
            this.options.onArrowUp(0, true); // call onArrowUp as handled
            return true;
          }
          /*
          SET beforeRange to Range(from current selection)
          SET beforeBottom to Bottom(from beforeRange)
  
          IF beforeRange?.startContainer.nodeType !== Node.TEXT_NODE THEN
            CALL getNodesBottom with beforeRange RETURNING beforeBottom
  
          CALL selection.modify with move, backward and line
  
          SET afterRange to Range(from current selection)
          SET afterBottom to Bottom(from afterRange)
  
          IF afterRange?.startContainer.nodeType !== Node.TEXT_NODE THEN
            CALL getNodesBottom with afterRange RETURNING afterBottom
  
          IF afterBottom = beforeBottom || Selection.atEnd(doc).anchor === selection.anchor  THEN
            move cursor to previous editor
          ELSE 
          move cursor naturally
          */
          editor.commands.focus();
          const beforeRange = document.getSelection()?.getRangeAt(0);
          let beforeBottom = Array.from(beforeRange?.getClientRects() || []).at(
            -1
          )?.bottom;
          if (beforeRange?.startContainer.nodeType !== Node.TEXT_NODE) {
            // nodeType is not textNode that means selection is not at text.
            // find bottom of the that element.
            beforeBottom = getNodesBottom(editor, this.options, beforeRange);
          }

          document.getSelection()?.modify("move", "backward", "line");

          const afterRange = document.getSelection()?.getRangeAt(0);
          let afterBottom =
            Array.from(afterRange?.getClientRects() || []).at(-1)?.bottom || 0;
          if (afterRange?.startContainer.nodeType !== Node.TEXT_NODE) {
            // nodeType is not textNode that means selection is not at text.
            // find bottom of the that element.
            afterBottom = getNodesBottom(editor, this.options, afterRange);
          }

          if (
            Selection.atStart(doc).anchor === selection.anchor ||
            beforeBottom === afterBottom ||
            afterBottom === -Infinity ||
            beforeBottom === -Infinity
          ) {
            this.options.onArrowUp(Infinity);
            return false;
          }
          this.options.onArrowUp(0, true); // call onArrowUp as handled
          return true;
        },
      onArrowDown:
        () =>
        ({ editor }) => {
          const {
            state: { selection, doc },
          } = editor;

          if (selection.from !== selection.to) {
            editor.commands.setTextSelection(selection.to);
            this.options.onArrowDown(0, true); // call onArrowDown as handled
            return true;
          }
          /*
          SET beforeRange to Range(from current selection)
          SET beforeBottom to Bottom(from beforeRange)
  
          IF beforeRange?.startContainer.nodeType !== Node.TEXT_NODE THEN
            CALL getNodesBottom with beforeRange RETURNING beforeBottom
  
          CALL selection.modify with move, backward and line
  
          SET afterRange to Range(from current selection)
          SET afterBottom to Bottom(from afterRange)
  
          IF afterRange?.startContainer.nodeType !== Node.TEXT_NODE THEN
            CALL getNodesBottom with afterRange RETURNING afterBottom
  
          IF afterBottom = beforeBottom || Selection.atEnd(doc).anchor === selection.anchor  THEN
            move cursor to next editor
          ELSE 
          move cursor naturally
          */
          editor.commands.focus();
          const beforeRange = document.getSelection()?.getRangeAt(0);
          let beforeBottom = Array.from(beforeRange?.getClientRects() || []).at(
            -1
          )?.bottom;
          if (beforeRange?.startContainer.nodeType !== Node.TEXT_NODE) {
            // nodeType is not textNode that means selection is not at text.
            // find bottom of the that element.
            beforeBottom = getNodesBottom(editor, this.options, beforeRange);
          }
          document.getSelection()?.modify("move", "forward", "line");

          const afterRange = document.getSelection()?.getRangeAt(0);
          let afterBottom =
            Array.from(afterRange?.getClientRects() || []).at(-1)?.bottom || 0;
          if (afterRange?.startContainer.nodeType !== Node.TEXT_NODE) {
            // nodeType is not textNode that means selection is not at text.
            // find bottom of the that element.
            afterBottom = getNodesBottom(editor, this.options, afterRange);
          }
          if (
            Selection.atEnd(doc).anchor === selection.anchor ||
            beforeBottom === afterBottom ||
            afterBottom === -Infinity ||
            beforeBottom === -Infinity
          ) {
            this.options.onArrowDown(-Infinity);
            return false;
          }
          this.options.onArrowDown(0, true); // call onArrowDown as handled
          return true;
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      ArrowUp: ({ editor }) => editor.commands.onArrowUp(),
      ArrowDown: ({ editor }) => editor.commands.onArrowDown(),
      ArrowRight: ({ editor }) => {
        const { state } = editor;
        const { selection, doc } = state;

        this.options.onArrowRight();

        if (Selection.atEnd(doc).anchor === selection.from) {
          this.options.onArrowDown(-Infinity);
          return true;
        }
        return false;
      },
      ArrowLeft: ({ editor }) => {
        const { state } = editor;
        const { selection, doc } = state;

        this.options.onArrowLeft();

        if (Selection.atStart(doc).anchor === selection.to) {
          this.options.onArrowUp(Infinity);
          return true;
        }
        return false;
      },
      Tab: ({ editor }) => {
        // Disable if on root node (for single node editor)
        if (shouldDisable(this.options.node, this.options.relativeDepth))
          return true;
        if (
          editor.commands.sinkListItem(editor.state.schema.nodes.listItem.name)
        ) {
          return true;
        }
        this.options.onSink();
        return true;
      },
      "Shift-Tab": ({ editor }) => {
        // Disable if on root node (for single node editor) with minimum depth of 1
        if (shouldDisable(this.options.node, this.options.relativeDepth, 1))
          return true;
        if (
          editor.commands.liftListItem(editor.state.schema.nodes.listItem.name)
        ) {
          return true;
        }
        this.options.onLift();
        return true;
      },
      "Control-n": ({ editor }) => editor.commands.onArrowDown(),
      "Control-p": ({ editor }) => editor.commands.onArrowUp(),
      Enter: ({ editor }) => {
        if (
          editor.commands.splitListItem(editor.state.schema.nodes.listItem.name)
        ) {
          return true;
        }
        if (shouldDisable(this.options.node, this.options.relativeDepth, -1))
          return false;
        // blur the editor so that next key press will be discarded (fixes EXE-405)
        // this is disabled on mobile because it causes keyboard to close each enter
        if (!isMobile) editor.view.dom.blur();
        // getting content to insert into new node
        const contentToInsertInNewNode = TextSelection.create(
          editor.state.doc,
          editor.state.selection.to,
          Selection.atEnd(editor.state.doc).anchor
        ).content().content;
        const newNodeWithContent = editor.schema.nodes.doc
          .createAndFill(null, contentToInsertInNewNode)
          ?.toJSON();

        let partialExistingNode: EfContentWithIds | null = null;
        if (
          editor.state.selection.to !== Selection.atEnd(editor.state.doc).anchor
        ) {
          // getting content to insert into existing node
          const contentToInsertInExistingNode = TextSelection.create(
            editor.state.doc,
            Selection.atStart(editor.state.doc).anchor,
            editor.state.selection.from
          ).content().content;
          const existingNodeWithContent = editor.schema.nodes.doc
            .createAndFill(null, contentToInsertInExistingNode)
            ?.toJSON();

          partialExistingNode = {
            tagIds: extractIds(existingNodeWithContent, "tag"),
            referencedPageIds: extractIds(existingNodeWithContent, "PageRef"),
            mentionIds: extractIds(existingNodeWithContent, "mention"),
            fileIds: extractIds(existingNodeWithContent, "file"),
            contentText: memoizedGenerateHTMLForNewTipTap(
              existingNodeWithContent
            ),
          };
        }
        const partialNewNode: EfContentWithIds = {
          tagIds: extractIds(newNodeWithContent, "tag"),
          referencedPageIds: extractIds(newNodeWithContent, "PageRef"),
          mentionIds: extractIds(newNodeWithContent, "mention"),
          fileIds: extractIds(newNodeWithContent, "file"),
          contentText: memoizedGenerateHTMLForNewTipTap(newNodeWithContent),
        };
        // this will go to history from onCreate method
        this.options.onCreate(
          partialExistingNode,
          partialNewNode,
          shouldDisable(this.options.node, this.options.relativeDepth)
        );
        return true;
      },
      Escape: () => {
        this.options.onEscape();
        return true;
      },
      Delete: ({ editor }) => {
        const {
          state: { selection, doc },
        } = editor;
        if (Selection.atEnd(doc).anchor !== selection.from) return false;
        // Disable if on root node (for single node editor)
        if (shouldDisable(this.options.node, this.options.relativeDepth))
          return true;
        this.options.onDeleteForward();
        return true;
      },
      Backspace: ({ editor }) => {
        const {
          state: { selection, doc },
        } = editor;
        if (Selection.atStart(doc).anchor !== selection.to) return false;
        // Disable if on root node (for single node editor)
        if (shouldDisable(this.options.node, this.options.relativeDepth))
          return true;
        this.options.onDeleteBackward();
        return true;
      },
      "Shift-ArrowUp": ({ editor }) => {
        const { selection } = editor.state;
        const { $head } = selection;

        // If we are selecting text and we arrow up on the first line,
        // the selection will be set to the start of the doc
        const onFirstLine =
          $head.pos === Selection.atStart(editor.state.doc).from;

        if (onFirstLine || this.options.isAreaSelection) {
          this.options.onSelectBackward();
          return true;
        }
        return false;
      },
      "Shift-ArrowDown": ({ editor }) => {
        const { selection } = editor.state;
        const { $head } = selection;

        // If we are selecting text and we arrow down on the last line,
        // the selection will be set to the end of the doc
        const onLastLine = $head.pos === Selection.atEnd(editor.state.doc).to;

        if (onLastLine || this.options.isAreaSelection) {
          this.options.onSelectForward();
          return true;
        }
        return false;
      },
      "Mod-c": ({ editor }) => {
        const { selection } = editor.state;
        const { from, to } = selection;

        // If full selection copy entire ef node
        const fromAtStart = from === Selection.atStart(editor.state.doc).from;
        const toAtEnd = to === Selection.atEnd(editor.state.doc).to;

        if (fromAtStart && toAtEnd) {
          // If a single full node has been copied, we want to copy the entire node
          const node = [this.options.node, [] as EfNode[], undefined] as const;
          const nodePromise = Promise.resolve(node);
          copyNodesToClipboard(nodePromise);
          return true;
        }
        return false;
      },
    };
  },
  addProseMirrorPlugins() {
    const { options, editor } = this;
    return [
      new Plugin({
        props: {
          handlePaste(_, event) {
            const htmlData = event.clipboardData?.getData("text/html");
            if (!htmlData) return false;
            // Check if copied html contains efNodes
            const efNodes = extractEfNodesFromHTML(htmlData);
            if (efNodes.length) {
              // In order to not duplicate nodes with the same IDs, we re-assign them to different IDs
              // But maintain their parent-child relations

              // Create a mapping of old IDs to new IDs
              const idMapping = new Map();
              efNodes.forEach((item) => {
                idMapping.set(item.id, v4());
              });

              // Update the objects with new IDs
              const updatedNodes = efNodes.map((item) => {
                const currentParent = item.parentId;
                const mappedParent = idMapping.get(currentParent);
                const newParentID = mappedParent || currentParent;
                return {
                  ...item,
                  id: idMapping.get(item.id),
                  parentId: newParentID,
                };
              });
              options.onPaste(updatedNodes);
              return true;
            } else {
              const htmlNodes = new DOMParser().parseFromString(
                htmlData,
                "text/html"
              );
              const hasLists = htmlNodes.querySelectorAll("ul, ol").length > 0;
              if (hasLists) {
                // If we detect a list, prompt the user to paste in efNodeStructure
                options.showConfirmPastePopup((pasteAsList) => {
                  if (pasteAsList) {
                    // If the user chooses to paste as a list, we convert the html to efNodeData
                    // And handle the paste with our custom onPaste function
                    const efNodeData = htmlToNestedEfNodes(htmlNodes);
                    options.onPaste(efNodeData);
                  } else {
                    // If not pasting as a list, we parse the html and let prosemirror handle it
                    const parser = PMDomParser.fromSchema(editor.state.schema);
                    const node = parser.parse(htmlNodes.body, {
                      preserveWhitespace: false,
                    });
                    const tr = editor.state.tr.replaceSelectionWith(node);
                    editor.view.dispatch(tr);
                  }
                });
                return true;
              }
              return false;
            }
          },
        },
      }),
    ];
  },
  addInputRules() {
    return [
      {
        find: /^\^\s$/,
        handler: ({ state, range }) => {
          const tagIds = this.options.getPreviousNodeTagIds();
          if (tagIds.length === 0) return;

          const tags = tagIds.map((id) =>
            state.schema.nodes.tag.create({ id })
          );

          state.tr
            .delete(range.from, range.to)
            .insertText(" ", range.from)
            .insert(range.from, tags)
            .scrollIntoView();
        },
      },
    ];
  },
});

const BASE_PARAMS = {
  nodeType: EfNodeType.Block,
  titleText: "",
  tagIds: [],
  referencedPageIds: [],
  mentionIds: [],
  properties: {},
};
// Takes pasted HTML doc and converts it to a EFNode structure
// Any lists inside the html will become nested EFNodes
const htmlToNestedEfNodes = (document: Document): ClipboardEfNode[] => {
  const json: ClipboardEfNode[] = [];

  const isList = (element: Element) => ["UL", "OL"].includes(element.tagName);

  const addNode = (
    id: string,
    parentId: string | null,
    position: string | null,
    contentText: string
  ) => {
    json.push({
      ...BASE_PARAMS,
      id,
      parentId,
      isRoot: !parentId,
      contentText,
      position,
    });
  };

  const hasNestedList = (children: HTMLCollection) =>
    Array.from(children).some(isList);

  const processList = (node: Element, parentID?: string, position?: string) => {
    const listChildren = Array.from(node.children) || []; // All child nodes in the list
    // When we detect a list, the lists parent is the node before it, so we keep track of it
    let prevItemID = parentID;
    // We also generate the max possible amount of position for its children (we might have x children but some of
    // them might be nested lists which do not get a position)
    const positions = generateNKeysBetween(
      position,
      undefined,
      listChildren.length
    );
    let positionIndex = 0;
    listChildren.forEach((child) => {
      if (isList(child)) {
        processList(child, prevItemID, undefined);
      } else if (child.tagName === "LI" && hasNestedList(child.children)) {
        // In some cases we might have a nested list inside a list item, as-well as the items content (notion)
        prevItemID = v4();
        child.childNodes.forEach((node) => {
          const isElement = node instanceof Element;
          if (isElement && isList(node)) {
            // If we detect a nested list in a list item, we process it as a list and remove it from the parent
            // So that we do not add it twice
            processList(node, prevItemID, undefined);
            child.removeChild(node);
          }
        });

        addNode(
          prevItemID,
          parentID || null,
          positions[positionIndex++],
          child.innerHTML.trimEnd() // Trim the end to remove the extra line that is added when removing children
        );
      } else {
        prevItemID = v4();
        addNode(
          prevItemID,
          parentID || null,
          positions[positionIndex++],
          child.innerHTML
        );
      }
    });
  };

  const processNode = (node: Element, parentID?: string, position?: string) => {
    if (["UL", "OL"].includes(node.tagName)) {
      // When we find a list we process them differently
      processList(node, parentID);
    } else {
      // Regular nodes get added as is
      addNode(v4(), parentID || null, position || null, node.innerHTML);
    }
  };

  const rootNodes = Array.from(document.body.children) || [];
  rootNodes.forEach((root) => processNode(root, undefined, undefined));
  return json;
};

/**
 * Calculated node's depth, accounts for relativeDepth (in SingleNodeEditor)
 * @param node
 * @param relativeDepth
 * @returns
 */
export const calcNodeDepth = (node: EfNode, relativeDepth = 0) => {
  const nodeDepthInPage = node.computed.pathInPage?.split("/").length || 0;
  return nodeDepthInPage - 3 - relativeDepth;
};

/**
 * Determines if we should disable a command, based on the node's relative depth
 * and the minimum depth required
 */
const shouldDisable = (
  node: EfNode,
  relativeDepth?: number,
  minRelativeDepth = 0
) => {
  if (relativeDepth === undefined) return;
  return calcNodeDepth(node, relativeDepth) <= minRelativeDepth;
};
