import { generateKeyBetween, generateNKeysBetween } from "fractional-indexing";
import { db } from "../../../db";
import { EfNode } from "../../../types";
import { updateNode } from "../../../utils";
import { getNodeChildren } from "./genericUtils";
import { useHistoryManager } from "@/hooks/useHistoryManager";

export const onSink =
  (
    history: ReturnType<typeof useHistoryManager>,
    node: EfNode,
    setCurrentId: (id: string) => void
  ) =>
  () => {
    history.run({
      redo: async () => {
        await db.transaction("rw", db.nodes, async () => await sinkNode(node));
      },
      undo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          await updateNode(node);
          setCurrentId(node.id);
        });
      },
    });
  };

// Sink
export const canSinkNode = async (node: EfNode) => {
  if (!node.parentId) return false;
  const siblings = await getNodeChildren(node.parentId);
  const indexInSiblings = siblings.findIndex(
    (sibling) => sibling.id === node.id
  );
  const newParent = siblings[indexInSiblings - 1];
  if (!newParent) return false;
  return true;
};
export const sinkNode = async (node: EfNode) => {
  if (!node.parentId) return false;
  const siblings = await getNodeChildren(node.parentId);
  const indexInSiblings = siblings.findIndex(
    (sibling) => sibling.id === node.id
  );
  const newParent = siblings[indexInSiblings - 1];
  if (!newParent) return false;

  const newSiblings = await getNodeChildren(newParent.id);
  const lastSibling = newSiblings[newSiblings.length - 1];
  const newPosition = generateKeyBetween(lastSibling?.position, null);

  await updateNode({
    ...node,
    parentId: newParent.id,
    position: newPosition,
  });
  return true;
};

// Lift
export const onLift =
  (
    history: ReturnType<typeof useHistoryManager>,
    node: EfNode,
    setCurrentId: (id: string) => void
  ) =>
  () => {
    history.run({
      redo: async () => {
        await db.transaction("rw", db.nodes, async () => await liftNode(node));
      },
      undo: async () => {
        await db.transaction("rw", db.nodes, async () => {
          await updateNode(node);
          setCurrentId(node.id);
        });
      },
    });
  };
export const canLiftNode = async (node: EfNode) => {
  if (!node.parentId) return false;
  const parent = await db.nodes.get(node.parentId);
  if (!parent || !parent.parentId) return false;
  return true;
};
export const liftNode = async (node: EfNode) => {
  if (!node.parentId) return;

  const children = await getNodeChildren(node.id);
  const lastChild = children[children.length - 1];

  const siblings = await getNodeChildren(node.parentId);
  const indexInSiblings = siblings.findIndex(
    (sibling) => sibling.id === node.id
  );

  const parent = await db.nodes.get(node.parentId);
  if (!parent || !parent.parentId) return;

  const newSiblings = await getNodeChildren(parent.parentId);
  const parentIndexInNewSiblings = newSiblings.findIndex(
    (sibling) => sibling.id === parent.id
  );
  const newPosition = generateKeyBetween(
    parent.position,
    newSiblings[parentIndexInNewSiblings + 1]?.position
  );

  await updateNode({
    ...node,
    parentId: parent.parentId,
    position: newPosition,
  });

  const siblingsAfter = siblings.slice(indexInSiblings + 1);
  const siblingsAfterPositions = generateNKeysBetween(
    lastChild?.position,
    null,
    siblingsAfter.length
  );
  await Promise.all(
    siblingsAfter.map((sibling, index) =>
      updateNode({
        ...sibling,
        parentId: node.id,
        position: siblingsAfterPositions[index],
      })
    )
  );
};
