import clsx from "clsx";
import { useEffect, useState } from "react";
import { btn } from "../styles/classes";

export function UpdateNotification() {
  const [show, setShow] = useState(false);
  const [initialContent, setInitialContent] = useState<null | string>(null);

  const fetchContent = async () => {
    try {
      const response = await fetch("/index.html");
      if (!response.ok) return null;
      return response.text();
    } catch {
      return null;
    }
  };

  useEffect(() => {
    fetchContent().then(setInitialContent);

    const interval = setInterval(async () => {
      const content = await fetchContent();
      if (content === initialContent) return;
      setShow(true);
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [initialContent]);

  if (!show) return null;

  return (
    <div className="border-t p-3 gap-1 flex">
      <p className="text-sm">A new version is available. Reload to update.</p>
      <button
        className={clsx(btn, "bg-yellow-300 hover:bg-yellow-400")}
        onClick={() => window.location.reload()}
      >
        Reload
      </button>
    </div>
  );
}
