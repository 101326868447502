import { DropTargetMonitor, useDrop } from "react-dnd";
import { EfNodeType } from "../graphql";
import { EfNode } from "../types";
import { updateNode } from "../utils";
import { isNil } from "lodash";
import { clearAllChildTags } from "../utils/getTagWithFullName";

interface TreeDropProps {
  canDrop: boolean;
  isDragging: boolean;
  node: EfNode | null;
  accept: EfNodeType;
  index?: number;
  changePosition?: (currentIndex: number, hoverIndex?: number) => void;
}
export function useTreeDrop({
  canDrop,
  isDragging,
  node,
  accept,
  index,
  changePosition,
}: TreeDropProps) {
  const checkCanDrop = () => canDrop && !isDragging;

  const onDrop =
    (parentId: string | null, updatePosition = false, dropAtLast = false) =>
    (
      item: { itemValue: EfNode; index: number },
      monitor: DropTargetMonitor<EfNode, EfNode>
    ) => {
      if (monitor.didDrop()) {
        // already dropped in nested node
        return;
      }
      clearAllChildTags(item.itemValue.id);
      updateNode({
        ...item.itemValue,
        parentId,
      });
      // run below code only in case when element is droped in between the nodes
      if (updatePosition && !isNil(index)) {
        // this is case where we are trying to drop an element from above position to below below position.
        // skip this only when we are trying to drop after last element.
        let indexToPush = index;
        if (!dropAtLast && item.index < index) {
          indexToPush = index - 1;
        }
        if (item.index !== indexToPush) {
          changePosition?.(item.index, indexToPush);
        }
      }
      return item;
    };

  const collect = (
    monitor: DropTargetMonitor<
      { itemValue: EfNode; index: number },
      { itemValue: EfNode; index: number }
    >
  ) => ({
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  });

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept,
      canDrop: checkCanDrop,
      drop: onDrop(node ? node.id : null),
      collect,
    }),
    [canDrop, isDragging, node, index]
  );

  const [{ isOverCurrent: isOverCurrentBetween }, dropBetween] = useDrop(
    () => ({
      accept,
      canDrop: checkCanDrop,
      drop: onDrop(node ? node.parentId : null, true),
      collect,
    }),
    [checkCanDrop, index, node]
  );

  const [{ isOverCurrent: isOverCurrentBetweenLast }, dropBetweenLast] =
    useDrop(
      () => ({
        accept,
        canDrop: checkCanDrop,
        drop: onDrop(node ? node.parentId : null, true, true),
        collect,
      }),
      [checkCanDrop, index, node]
    );

  return {
    isOverCurrentBetween,
    isOverCurrent,
    isOverCurrentBetweenLast,
    drop,
    dropBetween,
    dropBetweenLast,
  };
}
