import { GraphQLClient } from "graphql-request";
import { db } from "../db";
import { getSdk } from "./generated";
import { channel } from "../hooks/useAutoSync";
import { TabsMessageType } from "../types";
export * from "./generated";

// BASE_URL is configured in vercel  for staging and production env.
// For local BASE_URL will not be present thus fallback to default urls.
export const BASE_URL =
  process.env.BASE_URL ||
  (process.env.TEST ? "http://127.0.0.1:8000" : "https://api.execfxn.com");

const client = new GraphQLClient(`${BASE_URL}/graphql/`, {
  credentials: "include",
  responseMiddleware: async (response) => {
    if (
      response instanceof Error &&
      response.message.includes("UserNotLoggedInError")
    ) {
      await db.delete();
      localStorage.clear();
      channel.postMessage(TabsMessageType.RELOAD);
      window.location.assign(`${BASE_URL}/accounts/login/`);
    }
  },
});

export const api = getSdk(client);
