import { MutableRefObject, RefObject } from "react";
import { scrollNodeAboveKeyboard } from "../mobileUtils";
import { FocusMode } from "@/context/FocusMode";
import { EfNode } from "@/types";
import { isMobile } from "react-device-detect";
import { fixMobileScroll } from "./update";

export const onFocus =
  (
    node: EfNode,
    scrollRef: RefObject<HTMLElement> | undefined,
    setCurrentId: (id: string) => void,
    iosKeyboardHeight: number | undefined,
    focusModeDetails?: FocusMode,
    getFirstNodeInViewPort?: () => string | null | undefined,
    setFocusModeDetails?: (initialValue: FocusMode) => void,
    firstCommonEditorRef?: MutableRefObject<string | undefined>,
    nodeTopRef?: MutableRefObject<number | undefined>,
    getNodeScrollTop?: (nodeId?: string) => number | undefined,
    parentNodeId?: string,
    headerRef?: React.RefObject<HTMLElement>
  ) =>
  async (e: FocusEvent) => {
    const nodeElement = e.target as HTMLElement;
    const scrollElement = scrollRef?.current;
    fixMobileScroll(nodeElement, scrollElement, iosKeyboardHeight, headerRef);
    if (
      getFirstNodeInViewPort &&
      firstCommonEditorRef &&
      nodeTopRef &&
      parentNodeId
    ) {
      // added this check because when user moves cursor from one editor node to another we will reset the firstCommonNodeRef this will not cause shifting when node's height is changed.
      const firstNodeIdInViewPort = getFirstNodeInViewPort();
      if (firstNodeIdInViewPort && getNodeScrollTop) {
        firstCommonEditorRef.current = firstNodeIdInViewPort;
        // get scroll top because one node can be of multiple lines
        nodeTopRef.current =
          typeof getNodeScrollTop === "function"
            ? getNodeScrollTop(firstNodeIdInViewPort)
            : 0;
      }
      if (
        focusModeDetails?.activated &&
        (focusModeDetails?.firstUnFocusedNode?.computed.pathInPage || "") <=
          (node.computed.pathInPage || "")
      ) {
        setFocusModeDetails?.({
          activated: false,
          firstUnFocusedNode: undefined,
        });
      }
    }
    setCurrentId(node.id);
  };
