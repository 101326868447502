import { EfNodeType } from "../../graphql";
import { EfNode } from "../../types";

export const filterNodes = (query: string, node?: EfNode) => {
  if (!node || node.deleted) {
    return false;
  }
  const { titleText, nodeType } = node;

  // pre condition checks for tags and contacts
  if (
    (query.startsWith("#") && nodeType === EfNodeType.Tag) ||
    (query.startsWith("@") && nodeType === EfNodeType.Contact)
  ) {
    return !!titleText
      ?.toLowerCase()
      ?.includes(query.substring(1, query.length).toLowerCase());
  }
  // condition to filter pending task
  if (
    "task/pending".startsWith(query.toLowerCase()) &&
    titleText === "Pending"
  ) {
    return true;
  }

  // condition to filter completed task
  if (
    "task/completed".startsWith(query.toLowerCase()) &&
    titleText === "Completed"
  ) {
    return true;
  }
  // default condition
  return !!titleText?.toLowerCase()?.includes(query.toLowerCase());
};

export const getUpdatedQuery = (query: string) => {
  let updatedQuery = query;
  if (query.startsWith("#") || query.startsWith("@")) {
    updatedQuery = query.substring(1, query.length);
  }
  if ("task/pending".startsWith(query.toLowerCase())) {
    const pendingIndex = query.indexOf("p");
    if (pendingIndex >= 0) {
      updatedQuery = query.substring(pendingIndex, query.length);
    }
  }
  if ("task/completed".startsWith(query.toLowerCase())) {
    const completedIndex = query.indexOf("c");
    if (completedIndex >= 0) {
      updatedQuery = query.substring(completedIndex, query.length);
    }
  }
  return updatedQuery;
};
